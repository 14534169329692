import { useState, useEffect } from 'react'
import { ReturnHooks, IHooks } from './Type'

export const useCarousel = ({
  slides,
  id,
  move,
  numberOfImageInScreen,
  startAt,
}: IHooks): ReturnHooks => {
  const [index, setIndex] = useState<number>(0)
  const ids = {
    mover: `${id}-mover`,
    item: `${id}-item`,
  }

  const slidesLength = (slides && slides.length) || undefined

  const moveSlider = (index: number) => {
    const mover = document.getElementById(ids.mover) as HTMLElement
    const images = document.querySelectorAll(`.${ids.item}`)
    if (images && mover) {
      const imageSize = images[index]?.clientWidth as number | undefined
      if (mover && imageSize) {
        move({
          index,
          imageSize,
          moverElement: mover,
        })
      }
    }
  }

  const handleClickPrev = () => {
    if (index > 0) {
      setIndex(index - 1)
      moveSlider(index - 1)
    }
  }

  const handleClickNext = () => {
    if (slidesLength && index < slidesLength) {
      setIndex(index + 1)
      moveSlider(index + 1)
    }
  }

  useEffect(() => {
    if (startAt || startAt === 0) {
      setIndex(startAt)
      moveSlider(startAt)
    }
  }, [startAt])

  return {
    index,
    totalOfSlide: slidesLength || 0,
    indexShow: index + 1,
    showArrow: {
      next: !!(slidesLength && index <= slidesLength - (1 + numberOfImageInScreen)),
      prev: index > 0,
    },
    event: {
      next: handleClickNext,
      previous: handleClickPrev,
    },
    ids,
  }
}
