import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  flatsyGdprButton: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 16,
    padding: '0 24px',
    width: '100%',
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '24px',
    fontWeight: 300,
    color: theme.palette.secondary.main,
  },
  flatsyGdprLink: {
    textDecoration: 'underline',
    fontSize: 12,
    lineHeight: '24px',
    fontWeight: 300,
    color: theme.palette.secondary.main,
  },
}))

export default useStyles
