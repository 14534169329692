/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { FlatsyBaseCustomer } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Public<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Customer
   * @name CustomerDetail
   * @summary (Public) Retrieve a Customer by its id
   * @request GET:/public/customer/{id}
   * @secure
   */
  customerDetail = (id: string, params: RequestParams = {}) =>
    this.request<FlatsyBaseCustomer, void>({
      path: `/public/customer/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    })
}
