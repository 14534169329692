import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export const Header1Style = withStyles({
  root: {
    fontWeight: 300,
    fontSize: 22,
    lineHeight: '28px',
  },
})(Typography)

export const Header2Style = withStyles({
  root: {
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '24px',
  },
})(Typography)

export const Header3Style = withStyles({
  root: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
  },
})(Typography)
