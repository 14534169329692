import Separator from '@flatsy/core/Layout/Separator'
import LoaderWrapper from '@flatsy/core/Loader/Wrapper'
import { Header1 } from '@flatsy/core/MaterialUi/Typo'
import theme from '@flatsy/core/MuiTheme/theme'
import TimeBooking from '@flatsy/core/TimeBooking/Weeks/index'
import { Card, Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationContext } from 'src/context/Application'
import { renderFg } from '../Utils'
import { Bookable } from './Bookable'
import Sidebar from './Sidebar'
import { useStyles } from './Style'

const BookingOverview: React.FC = (): React.ReactElement => {
  const classes = useStyles()
  const { i18n } = useTranslation()
  const {
    missionQuery,
    booking: { bookable },
  } = useContext(ApplicationContext)

  if (missionQuery?.isLoading || !missionQuery || !missionQuery.data) {
    return <LoaderWrapper />
  }

  // like agency/src/containers/Missions/Booking/index
  return (
    <Grid container style={{ flex: 1 }}>
      <Grid item xs={9} className={classes.containerGrid}>
        <Card variant="outlined" className={classes.containerCard}>
          <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
            <Grid item xs={12} direction="row">
              <div className={classes.row}>
                <Header1 lowerCase style={{ flex: 1 }} fontColor={theme.palette.primary.dark}>
                  {i18n.t('book_your_inspection_appointment')}
                </Header1>
                {renderFg(missionQuery)}
              </div>
            </Grid>
            <Separator size="20px" />
            <Grid xs={12}>
              {bookable?.isLoading ? (
                <TimeBooking isLoading handleClickOnSlot={() => {}} handleWeekChange={() => {}} />
              ) : (
                <Bookable isLoading={missionQuery.isLoading} />
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={3} className={classes.containerGrid}>
        <Sidebar mission={missionQuery.data} />
      </Grid>
    </Grid>
  )
}

export default BookingOverview
