import {
  FlatsyPublicMissionCommercialViewing,
  FlatsyPublicMissionInspection,
} from '@flatsy/api/client/3.0.0/services/Mission/data-contracts'
import { Duration } from 'luxon'

export const renderDuration = (
  mission: FlatsyPublicMissionInspection | FlatsyPublicMissionCommercialViewing
): string | undefined => {
  if (mission.durationInMinutes) {
    const duree = Duration.fromObject({ minute: mission.durationInMinutes })
    if (mission.durationInMinutes % 60 === 0) {
      return duree.toFormat("h 'h'")
    }
    if (mission.durationInMinutes > 60) {
      return duree.toFormat("h 'h' m 'min'")
    }
    return duree.toFormat("m 'min'")
  }
  return undefined
}
