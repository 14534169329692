import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export const LabelSeparatorStyle = withStyles({
  root: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 400,
    marginBottom: 32,
  },
})(Typography)
