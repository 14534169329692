import clsx from 'clsx'
import { IconProps } from './Type'

export const createProps = (props: IconProps): IconProps => {
  const IconConstant = {
    className: 'MuiSvgIcon-root',
    style: {
      stroke: props.settings?.noStroke ? 'none' : 'currentColor',
      fill: props.settings?.noFill ? 'none' : 'currentColor',
      ...(props.fontSize && { fontSize: props.fontSize }),
    },
  }
  return {
    ...props,
    className: clsx(IconConstant.className, props.className),
    style: IconConstant.style,
  }
}
