import { format, resetHoursMinutes } from '@flatsy/utils/Functions/DateTime/Datefns'
import { parseApiDate } from '@flatsy/utils/Functions/DateTime/Date'
import { differenceInDays, max } from 'date-fns'
import { FlatsyPublicBookable } from '@flatsy/api/client/Booking/data-contracts'
import { HookReturn } from './Type'

const useSlots = (slots?: FlatsyPublicBookable[]): HookReturn => {
  let daysForward = 10
  const slotsByDay =
    slots?.reduce((formatedSlot: { [id: string]: string[] }, slot) => {
      const day = format(parseApiDate(`${slot.date}T${slot.start_time}`), 'P')
      if (!formatedSlot[day]) {
        formatedSlot[day] = []
      }
      formatedSlot[day].push(`${slot.date}T${slot.start_time}`)
      return formatedSlot
    }, {}) || {}

  if (slots) {
    const starts = slots.map((s) => parseApiDate(`${s.date}T${s.start_time}`))
    const maxDate = max(starts)
    daysForward =
      differenceInDays(resetHoursMinutes(parseApiDate(maxDate)), resetHoursMinutes(new Date())) + 1
  }

  return {
    slotsByDay,
    daysForward,
  }
}

export default useSlots
