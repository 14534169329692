import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  const spacing = theme.spacing(2)

  return {
    main: {
      marginTop: -spacing,
      paddingBottom: spacing,
      paddingTop: 2 * spacing,
      flexGrow: 1,
      width: '100%',
      maxWidth: 1440,
      display: 'flex',
      paddingLeft: 32,
      paddingRight: 32,
    },
    fullWidth: {
      width: '100%',
      maxWidth: '100%',
      position: 'relative',
    },
  }
})
