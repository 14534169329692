/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios'
import { SSO_BASE_URL } from '../constants'
import { FlatsyApiError, FlatsyValidationError } from './Types'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
  hybridToken?: string
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType
  private hybridToken?: string

  constructor({
    securityWorker,
    secure,
    format,
    hybridToken,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL,
    })
    this.instance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        const originalRequest = error.config
        //get refresh token
        const refreshToken = localStorage.getItem('refresh')
        const isRefresh = originalRequest.url.includes('token/refresh')

        if (
          refreshToken &&
          !isRefresh &&
          [401, 403].includes(error.response.status) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true
          return this.instance
            .post(`${SSO_BASE_URL}/token/refresh`, {
              refresh: refreshToken,
            })
            .then((res) => {
              // if refresh is on error then logout
              if (res.status === 200) {
                // update acces and refresh tokens
                localStorage.setItem('access', res.data.access)
                localStorage.setItem('refresh', res.data.refresh)

                // if origin is from token/verify, update data with new access token
                if (originalRequest.url.includes('token/verify')) {
                  originalRequest.data = {
                    token: res.data.access,
                  }
                }

                // re execute original request
                return this.instance(originalRequest)
              }
            })
        }
        return Promise.reject(error)
      }
    )
    this.instance.interceptors.request.use(
      (config) => {
        if (localStorage.getItem('access') && config.headers) {
          config.headers.Authorization = `Bearer ${localStorage.getItem('access')}`
        }

        //HACK: add hybrid token if indicated
        if (config.url && hybridToken) {
          if (config.url.indexOf('?') > -1) {
            config.url?.replace('?', `hybrid_token=${hybridToken}`)
          } else {
            config.url += `?hybrid_token=${hybridToken}`
          }
        }

        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )

    this.hybridToken = hybridToken
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    }).catch((err) => {
      const errorDetails = renderError(err)
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        errors: errorDetails.errors,
        validation_error: errorDetails.validation_error,
        code: errorDetails.code,
      })
    })
  }
}

/**
 * RenderError
 * @param error Error object from API
 * @returns Code & message
 */
// eslint-disable-next-line
const renderError = (error: any): FlatsyApiError => {
  let status = 404
  let errors: string[] = []
  let validation_error: FlatsyValidationError | undefined

  if (error && error.response && error.response) {
    if (error.response.status) {
      status = error.response.status
    }

    if (error.response.data && error.response.data.errors) {
      errors = error.response.data.errors
    }

    if (error.response.data && error.response.data.validation_error) {
      validation_error = error.response.data.validation_error
    }
  }
  return {
    code: status,
    errors,
    validation_error,
  }
}
