import { FlatsyPublicProperty } from '@flatsy/api/client/Property/data-contracts'

/**
 * @description build the final price with currency and currency suffix
 * @param asset Single asset object
 * @returns price formated with currency
 * @example "1000 € cc/m" || "1000 €"
 */
export function priceFormat(property: FlatsyPublicProperty): string {
  if (!property.prices) return ''

  if (property.transactionType === 'BUY') {
    const { buy } = property.prices
    return `${Math.trunc(buy?.price?.amount || 0)} €`
  }

  const { rent } = property.prices
  const baseRent = rent?.baseRent?.amount || 0
  const operatingCosts = rent?.operatingCosts?.amount || 0
  return `${baseRent + operatingCosts} € cc/m`
}

/**
 * @description build the final price of an asset
 * @param asset Single asset object
 * @returns final price
 */
export function priceValue(property: FlatsyPublicProperty): number {
  if (!property.prices) return 0

  if (property.transactionType === 'BUY') {
    const { buy } = property.prices
    return Math.trunc(buy?.price?.amount || 0)
  }
  const { rent } = property.prices
  const baseRent = rent?.baseRent?.amount || 0
  const operatingCosts = rent?.operatingCosts?.amount || 0
  return baseRent + operatingCosts
}

/**
 * @description get the currency suffix
 * @param asset Single asset object
 * @returns currency suffix : cc/m
 */
export function priceCurrency(property: FlatsyPublicProperty): string {
  if (property.transactionType === 'RENT') {
    return 'cc/m'
  }

  return ''
}

/**
 * @description convert number of floor into a plain text
 * @param floor number of floor
 * @returns the plain text floor
 */
export function floorFormat(floor: number, compact?: boolean): string {
  switch (floor) {
    case 0:
      return compact ? 'RDC' : 'Rez-de-chaussée'
    case 1:
      return `${floor}er étage`
    default:
      return `${floor}ème étage`
  }
}

/**
 *
 * @description convert number of rooms into a plain text
 * @param rooms number of rooms
 * @returns the plain text rooms
 */
export function roomsFormat(rooms: number): string {
  switch (rooms) {
    case 0:
      return `${rooms} pièce`
    case 1:
      return `${rooms} pièce`
    default:
      return `${rooms} pièces`
  }
}

export function bedroomsFormat(rooms: number): string {
  switch (rooms) {
    case 0:
      return `${rooms} chambre`
    case 1:
      return `${rooms} chambre`
    default:
      return `${rooms} chambres`
  }
}

/**
 * @description return surface with m² suffix
 * @param surface number
 * @returns plain text surface
 */
export function surfaceFormat(surface: number): string {
  return `${Math.trunc(surface)} m²`
}

export function cleanAddressState(address?: string): string {
  if (!address) {
    return ''
  }
  // keep this line to save it
  // const splited = address.split(",");
  // return splited.splice(0, splited.length - 1).join(",");
  return address
}

/**
 * @name shortAddress
 * @description convert address exemple 2 Rue Jos Kieffer, Esch-sur-Alzette, Luxembourg to Rue Jos Kieffer
 * @param {string} address
 * @return {*}  {string}
 */
export function shortAddress(address: string): string {
  const getFirstPart = address.split(',')[0]
  const removeNumber = getFirstPart.replace(/[0-9]/g, '')

  return removeNumber
}

// TODO: TEMPORARY delete when translations done
/**
 * @description convert key base string into plain text
 * @param type professional Status
 * @returns plain text for type
 * @example "alone" => "J'emménage seul"
 */
export function householdType(type: string): string {
  switch (type) {
    case 'alone':
      return "J'emménage seul"
    case 'roommates':
      return "J'emménage en colocation"
    case 'family':
      return "J'emménage en famille"
    default:
      return "J'emménage seul"
  }
}

/**
 * @description convert key base string into plain text
 * @param type professional Status
 * @returns plain text for type
 * @example "cdi_confirmed" => "CDI confirmé"
 */

export function professionalStatus(type: string): string {
  switch (type) {
    case 'cdi_confirmed':
      return 'CDI confirmé'
    case 'cdi_trial':
      return "CDI en période d'essai"
    case 'cdd_short':
      return 'CDD dont durée restante < 8 mois'
    case 'cdd_long':
      return 'CDD dont durée restante > 8 mois'
    case 'student':
      return 'Etudiant ou stagiaire'
    case 'civil_servant':
      return 'Fonctionnaire'
    case 'entrepreneur':
      return "Gérant d'entreprise"
    case 'liberal_profession':
      return 'Profession libérale ou indépendant'
    case 'retired':
      return 'Retraité'
    case 'unemployed':
      return 'Sans emploi'
    default:
      return 'CDI confirmé'
  }
}
/**
 * @description convert true or false into "Oui" | "Non"
 * @param type "true" | "false"
 * @returns "Oui" | "Non"
 */
export function isSelling(type: string): string {
  switch (type) {
    case 'true':
      return 'Oui'
    case 'false':
      return 'Non'
    default:
      return 'Non'
  }
}

/**
 * @description convert key base string into plain text
 * @param type purchase type
 * @returns plain text for type
 * @example "primary_residence" => "Résidence principale"
 */
export function purchaseMotive(type: string): string {
  switch (type) {
    case 'primary_residence':
      return 'Résidence principale'
    case 'rental_investment':
      return 'Investissement locatif'
    case 'secondary_residence':
      return 'Résidence secondaire'
    default:
      return 'Résidence principale'
  }
}
