import { AppBar, Box, LinkProps, Toolbar, Link as UILink } from '@material-ui/core'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, generatePath } from 'react-router-dom'
import * as ROUTES from 'src/constants/Routes'
import { ApplicationContext } from 'src/context/Application'
import { useMobile } from 'src/hooks/Mobile'
import { LogoAgency } from 'src/utils/LogoAgency'
import { useStyle } from './Styles'

const NavigationLink: React.FC<LinkProps<NavLink>> = (props) => {
  const classes = useStyle()
  const { className } = props

  return (
    <UILink
      component={NavLink}
      activeClassName={classes.navLinkActive}
      {...props}
      className={clsx(classes.navLink, className)}
      underline="none"
      variant="body1"
      color="textPrimary"
    />
  )
}

const Navigation: React.FC = (): React.ReactElement => {
  const classes = useStyle()
  const { i18n } = useTranslation()
  const isMobile = useMobile()
  const { token, customer } = useContext(ApplicationContext)

  if (isMobile) {
    return (
      <AppBar position="fixed" elevation={0} color="primary">
        {/* <NavigationLink
          to={{
            pathname: generatePath(ROUTES.APPLICATION, {
              token,
            }),
          }}
          className="dossier"
        > */}
        {/* <AccountCircleOutlinedIcon className={classes.navigationIcon} />
        <span className={classes.navigationLabel}>{i18n.t('file')}</span> */}
        {/* </NavigationLink> */}
      </AppBar>
    )
  }

  return (
    <Toolbar component="header" disableGutters className={classes.header}>
      <div className={classes.left}>
        {LogoAgency(classes, customer?.design?.logoUrl)}
        <Box component="nav" className={classes.nav}>
          <NavigationLink
            to={{
              pathname: generatePath(ROUTES.APPLICATION_FILES, {
                public_token: token,
              }),
            }}
          >
            {i18n.t('my_appointment')}
          </NavigationLink>
        </Box>
      </div>
    </Toolbar>
  )
}

export default Navigation
