import { Body1 } from '@flatsy/core/MaterialUi/Typo'
import { toNameCase } from '@flatsy/core/MaterialUi/Typo/Utile'
import { Avatar, Tooltip, useTheme } from '@material-ui/core'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useFlatguideById } from 'src/hooks/Flatguide'
import useStyle from './Style'
import { AvatarProps } from './Type'

export const AvatarFg: React.FC<AvatarProps> = ({ id }) => {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const classes = useStyle()
  const { data } = useFlatguideById(id)

  if (!data) {
    return <Skeleton variant="text" width="135px" />
  }

  const { firstName, lastName, photoURL } = data
  const fullname = `${toNameCase(firstName)} ${toNameCase(lastName)}`

  const renderFg = () => {
    if (photoURL) {
      return (
        <Tooltip title={fullname}>
          <Avatar alt={fullname} src={photoURL} className={clsx(classes.avatar, 'avatar')} />
        </Tooltip>
      )
    }
    return (
      <Tooltip title={i18n.t('pending_flatguide_assignment')}>
        <AccountCircleIcon fontSize="large" style={{ color: theme.palette.grey[500] }} />
      </Tooltip>
    )
  }

  return (
    <div className={classes.container}>
      <Body1 fontColor={theme.palette.secondary.dark}>{i18n.t('with')}</Body1>
      {renderFg()}
      <Body1>{data.firstName}</Body1>
    </div>
  )
}
