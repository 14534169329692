/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
import React, { useState, useEffect, forwardRef, Ref, useImperativeHandle } from 'react'
import { Snackbar, Portal } from '@material-ui/core'
import { Alert as MUIAlert } from '@material-ui/lab'
import { AlertRef, AlertProps } from './Type'

export const Alert = forwardRef(
  ({}, ref: Ref<AlertRef>): React.ReactElement => {
    const [alert, setAlert] = useState<AlertProps>({
      open: false,
      content: '',
      severity: 'info',
      icon: null,
    })

    const handleSetAlert = (props: AlertProps) => {
      setAlert(props)
    }

    useEffect(() => {
      if (!alert.open || alert.disableAutoClose) {
        return
      }
      const timeout = setTimeout(() => {
        setAlert({
          ...alert,
          open: false,
        })
      }, alert.timing || 3000)
      return () => clearTimeout(timeout)
    }, [alert])

    useImperativeHandle(ref, () => ({
      setAlert: handleSetAlert,
    }))

    return (
      <Portal>
        <Snackbar open={alert.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <MUIAlert severity={alert.severity} variant="filled" icon={alert.icon}>
            {alert.content}
          </MUIAlert>
        </Snackbar>
      </Portal>
    )
  }
)

export default Alert
