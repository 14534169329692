import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'calc(100vw/1.6)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  containerLarge: {
    width: '100%',
    height: 'auto',
    aspectRatio: '3.2/2',
    [theme.breakpoints.down('sm')]: {
      height: 260,
    },
  },
  containerSmall: {
    height: 200,
    [theme.breakpoints.down('md')]: {
      height: 414,
    },
  },
}))
