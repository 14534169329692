import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

/* ---------------------------------  BODY --------------------------------- */

export const Body1Style = withStyles({
  root: {
    fontSize: 16,
    lineHeight: '24px',
  },
})(Typography)

export const Body2Style = withStyles({
  root: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 300,
  },
})(Typography)

export const Body3Style = withStyles({
  root: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
})(Typography)

/* --------------------------------- SUB BODY --------------------------------- */

export const SubBody1Style = withStyles({
  root: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
  },
})(Typography)

export const SubBody2Style = withStyles({
  root: {
    fontWeight: 300,
    fontSize: 14,
    lineHeight: '16px',
    letterSpacing: '-0.02em',
  },
})(Typography)

export const SubBody3Style = withStyles({
  root: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.04em',
  },
})(Typography)
