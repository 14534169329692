import { createTheme } from '@material-ui/core/styles'

interface AdminColorPalette {
  navigation_light: string
  navigation_dark: string
}
const color = {
  common: {
    black: '#001345',
  },
  primary: {
    main: '#01237E',
    // main: '#2AA5DF',
    contrastText: '#fff',
    dark: '#01237E',
    light: '#E9F7FE',
  },
  secondary: {
    main: '#95969A',
    light: '#ECEEF3',
    dark: '#727379',
    contrastText: '#001345',
  },
  error: {
    main: '#FF6666',
    light: '#FDECEC',
    dark: '#C6323C',
  },
  warning: {
    main: '#FCA932',
    light: '#FFF2E1',
    dark: '#C47A00',
  },
  success: {
    main: '#65C87A',
    light: '#EDF9F2',
    dark: '#30974D',
  },
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    admin: AdminColorPalette
  }
  interface PaletteOptions {
    admin: AdminColorPalette
  }
}

const theme = createTheme({
  zIndex: {
    modal: 9999999999999,
  },
  palette: {
    common: color.common,
    primary: color.primary,
    secondary: color.secondary,
    error: color.error,
    warning: color.warning,
    success: color.success,
    admin: {
      navigation_dark: '#001345',
      navigation_light: '#1A2B58',
    },
    text: {
      primary: color.common.black,
    },
  },
  typography: {
    fontFamily: 'sf-pro-text, sans-serif',
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: color.common.black,
          height: 'auto',
          minHeight: '100%',
        },
        '*::-webkit-scrollbar': {
          width: '6px',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.5)',
          borderRadius: '4px',
        },
        '@keyframes shineBackground': {
          '0%': {
            backgroundPosition: '100%',
          },
          '100%': {
            backgroundPosition: '0%',
          },
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: color.primary.light,
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: color.primary.light,
          color: color.primary.main,
          '& .MuiListItemIcon-root': {
            color: color.primary.main,
          },
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'initial',
        borderRadius: 8,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
    MuiCheckbox: {
      root: {
        color: color.primary.main,
        '&.Mui-checked, &.MuiCheckbox-colorSecondary.Mui-checked': {
          color: color.primary.main,
        },
      },
    },
    MuiSwitch: {
      root: {
        '& .MuiSwitch-switchBase': {
          color: `${color.secondary.main}!important`,
        },
        '& .MuiSwitch-colorSecondary + .MuiSwitch-track': {
          backgroundColor: `${color.secondary.main}!important`,
          opacity: 0.5,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: `${color.primary.main}!important`,
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
          backgroundColor: `${color.primary.main}!important`,
          opacity: 0.5,
        },
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 99999999999999999999,
        '& .MuiAlert-filledError': {
          background: color.error.light,
          color: color.error.dark,
        },
        '& .MuiAlert-filledSuccess': {
          background: color.success.light,
          color: color.success.dark,
        },
        '& .MuiAlert-filledInfo': {
          background: color.primary.light,
          color: color.primary.dark,
        },
      },
    },
    MuiDialog: {
      root: {
        '& .MuiDialog-paperScrollPaper': {
          maxHeight: 'calc(100% - 16px)',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
  },
})

export default theme
