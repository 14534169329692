import { FlatsyPublicProperty } from '@flatsy/api/client/3.0.0/services/Property/data-contracts'
import Carousel from 'src/components/Carousel'

export const renderCarousel = (property: FlatsyPublicProperty | undefined): React.ReactNode => {
  if (
    property &&
    property.media &&
    property.media.pictures &&
    property.media.pictures?.length !== 0
  ) {
    return <Carousel size="small" property={property} noBorderRadius />
  }
}
