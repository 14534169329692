import clsx from 'clsx'
import React, { HTMLProps } from 'react'
import { useStyles } from './Style'

export const LayoutMain: React.FC<HTMLProps<HTMLDivElement> & { fluidWidth?: boolean }> = ({
  children,
  className,
  fluidWidth,
  ...props
}): React.ReactElement => {
  const classes = useStyles()

  return (
    <main className={clsx(classes.main, fluidWidth && classes.fullWidth, className)} {...props}>
      {children}
    </main>
  )
}
