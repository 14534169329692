/* eslint-disable @typescript-eslint/ban-ts-comment */
import { makeStyles } from '@material-ui/core'
import { ExtenedTypographyProps } from './Type'

export const useTypoStyles = makeStyles((theme) => ({
  color: (props: ExtenedTypographyProps) => ({
    color: props.fontColor || theme.palette.common.black,
  }),
  Upper: {
    textTransform: 'uppercase',
  },
  Lower: {
    textTransform: 'initial',
  },
}))

export const toNameCase = (word?: string): string => {
  if (!word) return ''
  const _word = word.toLowerCase()
  return _word[0].toUpperCase() + _word.substring(1).toLowerCase()
}
