import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => {
  return {
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    inner: {
      position: 'relative',
      width: 30,
      height: 30,
    },
    spinner: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      width: 30,
      height: 30,
      animation: 'rotate-all 1s linear infinite',
    },
  }
})
