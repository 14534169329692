/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>

/**
 * Indicates who will perform the mission:
 *   * `FLATGUIDE` - An Agent from Flatsy
 *   * `COLLABORATOR` - A Customer's team member
 * @format string
 */
export enum FlatsyAgentType {
  FLATGUIDE = 'FLATGUIDE',
  COLLABORATOR = 'COLLABORATOR',
}

/** Bookable */
export interface FlatsyPublicBookable {
  /** @format date-time */
  startsAt: string
  score: number
  /**
   * @pattern ^(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$$
   * @example "01:00:00"
   */
  duration?: string
}

/** Booking */
export interface FlatsyPublicBooking {
  /**
   * @format uuid
   * @pattern ^[\w\-. ]+$
   */
  id?: string
  mission?: {
    /**
     * ID of the Mission
     * @format uuid
     * @pattern ^[\w\-. ]+$
     */
    id?: string
    /** Mission type */
    type?: FlatsyMissionType
  }
  property?: {
    /**
     * ID of the Property the Booking is for
     * @format uuid
     * @pattern ^[\w\-. ]+$
     */
    id?: string
  }
  /** Person performing the mission */
  agent?: {
    /**
     * ID of the Agent performing the mission
     * @format uuid
     * @pattern ^[\w\-. ]+$
     */
    id?: string
    /**
     * Indicates who will perform the mission:
     *   * `FLATGUIDE` - An Agent from Flatsy
     *   * `COLLABORATOR` - A Customer's team member
     * @format string
     */
    type?: 'FLATGUIDE' | 'COLLABORATOR'
  }
  /** @format date-time */
  startsAt: string
  state?: FlatsyBookingState
  durationInMinutes?: number
}

/** Booking Record */
export type FlatsyProBooking = FlatsyPublicBooking & {
  attendee?: FlatsyAttendee
  /**
   * Code to be given to the Agent to validate the Booking has occured
   * @example "4578"
   */
  code?: string
  /**
   * @format date-time
   * @example "2023-12-30T10:00:00+02:00"
   */
  createdAt?: string
  /**
   * @format date-time
   * @example "2023-12-30T10:00:00+02:00"
   */
  updatedAt?: string
}

/** Booking */
export interface FlatsyPostBooking {
  /** @format uuid */
  missionId: string
  /** @format date-time */
  startsAt: string
}

/** @default "PROPOSED" */
export enum FlatsyBookingState {
  PROPOSED = 'PROPOSED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED_BY_VISITOR = 'CANCELLED_BY_VISITOR',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  CANCELLED_BY_AGENT = 'CANCELLED_BY_AGENT',
  CANCELLED_RESCHEDULED = 'CANCELLED_RESCHEDULED',
  CANCELLED_TERMINATED = 'CANCELLED_TERMINATED',
  ATTENDED = 'ATTENDED',
}

/** Booking */
export interface FlatsyPatchBooking {
  agent: {
    /**
     * ID of the Mission
     * @format uuid
     * @pattern ^[\w\-. ]+$
     */
    id?: string
    /**
     * Indicates who will perform the mission:
     *   * `FLATGUIDE` - An Agent from Flatsy
     *   * `COLLABORATOR` - A Customer's team member
     */
    type?: FlatsyAgentType
  }
  /**
   * @default 30
   * @example 120
   */
  durationInMinutes: number
}

/** Booking */
export interface FlatsyPatchBookingMission {
  /**
   * ID of the Mission
   * @format uuid
   * @pattern ^[\w\-. ]+$
   */
  id: string
  /** Mission type */
  type?: FlatsyMissionType
}

/** Booking */
export interface FlatsyPatchBookingState {
  state: FlatsyBookingState
}

/** Booking */
export interface FlatsyPatchBookingStartsAt {
  /** @format date-time */
  startsAt: string
}

export type FlatsyProBookable = FlatsyPublicBookable & {
  reasons?: string[]
}

/** Mission record */
export interface FlatsyMissionEnriched {
  /** @format uuid */
  id?: string
  /**
   * @default 3
   * @example "3.0"
   */
  version: string
  /** Mission type */
  type: FlatsyMissionType
  /** @format uuid */
  propertyId: string
  customer?: {
    /** @format uuid */
    id?: string
    /** @format uuid */
    name?: string
  }
  /**
   * @default 30
   * @example 120
   */
  durationInMinutes?: number
  /**
   * @default 15
   * @example 15
   */
  incrementInMinutes?: number
  operations?: FlatsyMissionOperations
  /**
   * @format date-time
   * @example "2023-12-30T10:00:00+02:00"
   */
  createdAt?: string
  /**
   * @format date-time
   * @example "2023-12-30T10:00:00+02:00"
   */
  updatedAt?: string
  /**
   * @format date-time
   * @example "2023-12-30T10:00:00+02:00"
   */
  completedAt?: string
}

/**
 * Mission type
 * @example "INSPECTION"
 */
export enum FlatsyMissionType {
  INSPECTION = 'INSPECTION',
  COMMERCIAL_VIEWING = 'COMMERCIAL_VIEWING',
  PHOTO = 'PHOTO',
  DOOR_OPENING = 'DOOR_OPENING',
}

/** Assigning a FlatGuide for a Mission */
export interface FlatsyPostAssignment {
  agent: {
    /**
     * ID of the Mission
     * @format uuid
     * @pattern ^[\w\-. ]+$
     */
    id?: string
    /**
     * Indicates who will perform the mission:
     *   * `FLATGUIDE` - An Agent from Flatsy
     *   * `COLLABORATOR` - A Customer's team member
     */
    type?: FlatsyAgentType
  }
  /** @format date */
  startsAt: string
  /** @format date */
  endsAt?: string
  travelToProperty?: {
    /**
     * Time in minutes needed to travel from the Agent's home to the Property
     * @example 12
     */
    durationInMinutes?: number
    /**
     * Distance in kilometres from the agent's home to the property
     * @example 2.5
     */
    distanceInKilometers?: number
  }
  /** Rate offered to the agent for the Assignment */
  missionRate?: number
}

/** Patching a booking dates and/or state */
export interface FlatsyPatchAssignment {
  /** @format date */
  startsAt?: string
  state?: FlatsyAssignmentState
  /** @format date */
  endsAt?: string
  travelToProperty?: {
    /**
     * Time in minutes needed to travel from the Agent's home to the Property
     * @example 12
     */
    durationInMinutes?: number
    /**
     * Distance in kilometres from the agent's home to the property
     * @example 2.5
     */
    distanceInKilometers?: number
  }
  /** Rate offered to the agent for the Assignment */
  missionRate?: number
}

export interface FlatsyPublicAssignment {
  agent?: {
    /**
     * ID of the Mission
     * @format uuid
     * @pattern ^[\w\-. ]+$
     */
    id?: string
    /**
     * Indicates who will perform the mission:
     *   * `FLATGUIDE` - An Agent from Flatsy
     *   * `COLLABORATOR` - A Customer's team member
     */
    type?: FlatsyAgentType
  }
  state?: FlatsyAssignmentState
  /** @format date */
  startsAt?: string
  /** @format date */
  endsAt?: string
}

export interface FlatsyAdminAssignment {
  agent?: {
    /**
     * ID of the Mission
     * @format uuid
     * @pattern ^[\w\-. ]+$
     */
    id?: string
    /**
     * Indicates who will perform the mission:
     *   * `FLATGUIDE` - An Agent from Flatsy
     *   * `COLLABORATOR` - A Customer's team member
     */
    type?: FlatsyAgentType
  }
  state?: FlatsyAssignmentState
  /** @format date */
  startsAt?: string
  /** @format date */
  endsAt?: string
  travelToProperty?: {
    /**
     * Time in minutes needed to travel from the Agent's home to the Property
     * @example 12
     */
    durationInMinutes?: number
    /**
     * Distance in kilometres from the agent's home to the property
     * @example 2.5
     */
    distanceInKilometers?: number
  }
  /** Rate offered to the agent for the Assignment */
  missionRate?: number
}

export type FlatsyAssignmentListItem = FlatsyAdminAssignment & {
  agent?: {
    /** @format uuid */
    id?: string
    /** @maxLength 150 */
    firstName?: string
    /** @maxLength 150 */
    lastName?: string
    /**
     * @maxLength 15
     * @pattern ^\+(?:[0-9] ?){6,14}[0-9]$
     */
    phoneNumber?: string | null
    /** @format url */
    photoURL?: string
  }
}

export enum FlatsyAssignmentState {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  OFFERED = 'OFFERED',
  WITHDRAWN = 'WITHDRAWN',
}

export interface FlatsyAttendee {
  /** @example "Olivier" */
  firstName?: string
  /** @example "Dupont" */
  lastName?: string
  /** @example "odupont@example.com" */
  email?: string
  /**
   * @maxLength 50
   * @example "+33678901234"
   */
  phoneNumber?: string
}

export interface FlatsyMissionMedia {
  input?: {
    pictures?: string[]
    documents?: {
      /**
       * URL to generate the PDF file required for ERP
       * @format url
       */
      erp?: string
      /**
       * URL to retrieve the DPE certificate file if it exists
       * @format url
       */
      dpe?: string
    }
  }
}

export interface FlatsyMissionInspectionMedia {
  supporting?: {
    documents?: {
      /**
       * URL for the PDF file from the previous EDL
       * @format url
       */
      report?: string
    }
  }
  deliverables?: {
    documents?: {
      /**
       * URL for the PDF file from the EDL
       * @format url
       */
      report?: string
    }
  }
}

export interface FlatsyMissionOperations {
  bookings?: {
    /**
     * Indicates whether or not Bookings can be made for this Mission
     * @default true
     */
    areAllowed?: boolean
    /**
     * The current maximum count of valid Bookings allowed for the Mission
     * @default 7
     * @example 7
     */
    currentThreshold?: number
    /**
     * The maximum count of valid Bookings allowed for the Mission
     * @default 7
     * @example 7
     */
    initialThreshold?: number
    /**
     * The current count of valid Bookings made for the Mission
     * @default 7
     * @example 7
     */
    validCount?: number
  }
}

export interface FlatsyMissionStaffing {
  /**
   * Indicates whether or not we have an issue with the mission missing Assignments at one point in time
   * @default true
   */
  missingAssignment?: boolean
  assignments?: FlatsyAdminAssignment[]
}

export type FlatsyMissionStaffingEnriched = FlatsyMissionStaffing & {
  assignments?: FlatsyAssignmentListItem[]
}

/** @default "DRAFT" */
export enum FlatsyMissionState {
  ACCEPTED = 'ACCEPTED',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  CANCELLED_BY_FLATSY = 'CANCELLED_BY_FLATSY',
  COMPLETED = 'COMPLETED',
  DECLINED_BY_AGENT = 'DECLINED_BY_AGENT',
  DECLINED_BY_FLATSY = 'DECLINED_BY_FLATSY',
  DISPUTED = 'DISPUTED',
  DRAFT = 'DRAFT',
  ORDERED = 'ORDERED',
  ON_HOLD_BY_AGENT = 'ON_HOLD_BY_AGENT',
  ON_HOLD_BY_CUSTOMER = 'ON_HOLD_BY_CUSTOMER',
  ON_HOLD_BY_FLATSY = 'ON_HOLD_BY_FLATSY',
}

export interface FlatsyApplicationMissionActivityDetails {
  /** @example true */
  isAccepted?: boolean
  /** @example true */
  isGuarantorRequired?: boolean
  /** @example 6 */
  mininumActivityDurationInMonths?: number | null
}

/** Patching a Mission state */
export interface FlatsyProMissionStatePatch {
  /** @default "CANCELLED_BY_CUSTOMER" */
  state: 'CANCELLED_BY_CUSTOMER'
  /** @example "MISSION_HAS_BEEN_CANCELLED_BY_TENANT" */
  reason:
    | 'MISSION_WILL_BE_CONDUCTED_OUTSIDE_FLATSY'
    | 'MISSION_IS_A_DUPLICATE'
    | 'INVOLVED_TENANT_HAS_CHANGED'
    | 'MISSION_HAS_BEEN_CANCELLED_BY_TENANT'
    | 'OTHER'
  /**
   * Free text allowing the user tp explain the reason further
   * @format string
   * @example "Hiring process of new Flatguide in the area is still a work in progress. Should be okay by end of the year"
   */
  optionalComment?: string
}

/** Patching a Mission Bookings Threshold */
export interface FlatsyPatchMissionBookingsThreshold {
  /** Must be greater than bookings.initialThreshold */
  currentThreshold: number
}

/** Patching a Mission Bookings allowance */
export interface FlatsyPatchMissionBookingAllowance {
  action:
    | 'BookingCreated'
    | 'BookingCancelled'
    | 'MissionBookingsThresholdIncreased'
    | 'MissionBookingsAllowanceRecalculated'
}

export type FlatsyPostMissionInspection = UtilRequiredKeys<
  FlatsyPublicMission,
  'type' | 'propertyId' | 'customerId'
> & {
  /** @example "La porte d'entrée est dure à ouvrir." */
  instructions?: string
  inspection?: ({
    /**
     * TBD
     * @example 10
     */
    daysForward?: number
    /**
     * TBD
     * @example 90
     */
    maxViewingsSlots?: number
    /**
     * TBD
     * @example 10
     */
    maxViewingsPerDay?: number
    /**
     * The maximum count of valid Bookings allowed for the Mission (For Inspections, it set at 1 by default)
     * @default 1
     * @example 1
     */
    initialBookingsThreshold?: number
    bookingHours?: any
    /**
     * Whether the booking slot should be selected by the Customer or the tenant himself
     * @default "CUSTOMER"
     * @example "CUSTOMER"
     */
    bookingSelectionMode?: 'CUSTOMER' | 'TENANT' | 'FLATSY'
    /**
     * The report produced at the end of the Inspection should be branded using the Customer logo, adress and legal information.
     * @default false
     */
    requiresBrandedInspectionReport?: boolean
    /**
     * Indicates whether a mission of this type should be created for a newly imported property
     * @default false
     * @example false
     */
    isCreatedOnPropertyImport?: boolean
    tool?: {
      /**
       * The Inspection tool the Customer requires the Flatguide to use for the Inspection
       * @default "NOCKEE"
       * @example "NOCKEE"
       */
      default?: 'NOCKEE' | 'ELYPSO' | 'PAPER'
      /**
       * The Inspection tool to use should the InspectionTool is unavailable
       * @default "NOCKEE"
       * @example "PAPER"
       */
      fallback?: 'NOCKEE' | 'PAPER'
    }
  } & object) & {
    type?: 'ENTRY' | 'EXIT'
    preferredDates?: {
      /**
       * Earliest date the Customer would prefer the mission woud take place at
       * @format date
       */
      startsAt?: string
      /**
       * Latest date the Customer would prefer the mission woud take place at
       * @format date
       */
      endsAt?: string
    }
    /**
     * Email address where the Inspection result should be sent
     * @example "odupont@example.com"
     */
    recipientEmail?: string
  }
  property?: {
    location?: any
    estateType?: any
    spaces?: any
    structure?: any
    publication?: any
    features?: any
    energy?: any
  }
  tenants?: {
    /** @example "Olivier" */
    firstName?: string
    /** @example "Dupont" */
    lastName?: string
    /** @example "odupont@example.com" */
    email?: string
    /**
     * @maxLength 50
     * @example "+33678901234"
     */
    phoneNumber?: string
    /**
     * Mandatory in tha case of an Inspection of EXIT type
     * @example "52 rue d'aboukir"
     */
    address?: string
    /**
     * Mandatory in tha case of an Inspection of EXIT type
     * @maxLength 50
     * @example "75002"
     */
    postalCode?: string
    /**
     * Mandatory in tha case of an Inspection of EXIT type
     * @maxLength 50
     * @example "Paris"
     */
    locality?: string
  }[]
  landlord?: {
    /** @example "Olivier" */
    firstName?: string
    /** @example "Dupont" */
    lastName?: string
    /** @example "odupont@example.com" */
    email?: string
    /** @example "52 rue d'aboukir" */
    address?: string
    /**
     * @maxLength 50
     * @example "75002"
     */
    postalCode?: string
    /**
     * @maxLength 50
     * @example "Paris"
     */
    locality?: string
  }
  thirdParty?: {
    /**
     * Nockee report has been copied from another Nockee report with this id
     * @example "12345678-5717-4562-b3fc-123456789012"
     */
    copiedFrom?: string
  }
}

export type FlatsyMissionListItem = UtilRequiredKeys<FlatsyMissionEnriched, 'type' | 'propertyId'> &
  FlatsyProMissionInspection & {
    staffing?: FlatsyMissionStaffingEnriched
  }

/** (Admin) Patching a Mission state */
export interface FlatsyPatchAdminMissionState {
  state: FlatsyMissionState
  /** @example "NO_FLATSY_COVERAGE_OF_AREA" */
  reason?:
    | 'DEADLINE_TOO_CLOSE'
    | 'MISSING_OR_INCOMPLETE_MISSION_INFORMATION'
    | 'NO_AVAILABLE_FLATGUIDE'
    | 'NO_FLATSY_COVERAGE_OF_AREA'
    | 'NO_QUALIFIED_FLATGUIDE'
    | 'MISSION_IS_A_DUPLICATE'
  /**
   * Free text allowing the user tp explain the reason further
   * @format string
   * @example "Hiring process of new Flatguide in the area is still a work in progress. Should be okay by end of the year"
   */
  optionalComment?: string
}

/** (System) Patching a Mission state */
export type FlatsyPatchSystemMissionState = FlatsyPatchAdminMissionState

/** (Public) Mission record */
export interface FlatsyPublicMission {
  /** @format uuid */
  id?: string
  /** Mission type */
  type: FlatsyMissionType
  currentState?: {
    state?: FlatsyMissionState
  }
  /** @format uuid */
  propertyId: string
  /** @format uuid */
  customerId: string
  bookings?: string[]
  operations?: FlatsyPublicMissionOperations
  /** Anything relating to the staffing of a mission */
  staffing?: FlatsyPublicMissionStaffing
  /**
   * @default 30
   * @example 120
   */
  durationInMinutes?: number
}

/** Mission of type Inspection */
export type FlatsyPublicMissionInspection = FlatsyPublicMission & {
  inspection?: {
    type?: 'ENTRY' | 'EXIT'
    preferredDates?: {
      /**
       * Earliest date the Customer would prefer the mission woud take place at
       * @format date
       */
      startsAt?: string
      /**
       * Latest date the Customer would prefer the mission woud take place at
       * @format date
       */
      endsAt?: string
    }
    /** List of days/hours where the Customer has allowed bookings for this missin */
    bookingHours?: {
      /** @example "MONDAY" */
      day: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURSDAY' | 'SUNDAY'
      times?: {
        /**
         * @format time
         * @pattern ^(?:[01]\d|2[0-3]):[0-5]\d$
         * @example "09:30"
         */
        openingTime: string
        /**
         * @format time
         * @pattern ^(?:[01]\d|2[0-3]):[0-5]\d$
         * @example "20:30"
         */
        closingTime: string
      }[]
    }[]
  }
}

export type FlatsyPublicMissionCommercialViewingBuy = FlatsyPublicMission & {
  commercialViewing?: {
    /**
     * Indicates the specific type of sale or rental. Mandatory.
     * @example "BUY"
     */
    type?: 'BUY' | 'RENT'
    bookingHours?: any
  }
}

export type FlatsyPublicMissionCommercialViewingRent = FlatsyPublicMission & {
  commercialViewing?: {
    /** @example "RENT" */
    type?: any
    bookingHours?: any
    applicationDocuments?: {
      /**
       * Indicates when the Applicant needs to upload the required documents for their application.
       * @default "AFTER_BOOKING"
       * @example "AFTER_BOOKING"
       */
      request?: 'NEVER' | 'BEFORE_BOOKING' | 'AFTER_BOOKING' | 'AFTER_VIEWING'
    }
    applicationPdf?: {
      /**
       * URL of a specific Application document the Customer needs the Applicant to fill to apply for this Property
       * @format url
       */
      url?: string
    }
    /**
     * Indicates the number of retries for an application.
     * @default 1
     */
    maximumQualificationAttemptsByApplicant?: number
    thirdPartyApplicationProvider?: {
      /**
       * Defines if the Applicant should be redirect to a thrid party system to handle their application using the url provided
       * @format url
       */
      url?: string
    }
  }
}

export type FlatsyPublicMissionCommercialViewing =
  | FlatsyPublicMissionCommercialViewingBuy
  | FlatsyPublicMissionCommercialViewingRent

export interface FlatsyPublicMissionOperations {
  bookings?: {
    /**
     * Indicates whether or not Bookings can be made for this Mission
     * @default true
     */
    areAllowed?: boolean
  }
}

export type FlatsyProMissionOperations = FlatsyPublicMissionOperations & {
  bookings?: {
    /**
     * The current maximum count of valid Bookings allowed for the Mission
     * @default 7
     * @example 7
     */
    currentThreshold?: number
    /**
     * The maximum count of valid Bookings allowed for the Mission
     * @default 7
     * @example 7
     */
    initialThreshold?: number
    /**
     * The current count of valid Bookings made for the Mission
     * @default 7
     * @example 7
     */
    validCount?: number
  }
}

export interface FlatsyPublicMissionStaffing {
  assignments?: FlatsyPublicAssignment[]
}

/** Mission Record */
export type FlatsyProMission = FlatsyPublicMission & {
  /**
   * @default 3
   * @example "3.0"
   */
  version?: string
  /** @example "agence_bleue_propref" */
  slug?: string
  /**
   * @format date-time
   * @example "2023-12-30T10:00:00+02:00"
   */
  createdAt?: string
  /**
   * @format date-time
   * @example "2023-12-30T10:00:00+02:00"
   */
  updatedAt?: string
  /**
   * @format date-time
   * @example "2023-12-30T10:00:00+02:00"
   */
  completedAt?: string
  /**
   * @default 15
   * @example 15
   */
  incrementInMinutes?: number
  bookings?: string[]
  staffing?: FlatsyMissionStaffing
  currentState?: {
    state?: string
    /**
     * @format date-time
     * @example "2023-12-30T10:00:00+02:00"
     */
    changedAt?: string
    reason?: string
    /** @example "CUSTOMER" */
    actor?: 'APPLICANT' | 'CSM' | 'CUSTOMER' | 'FLATGUIDE' | 'OPS' | 'SYSTEM'
    /** @format uuid */
    actorId?: string
    /**
     * Free text allowing the user tp explain the reason further
     * @format string
     * @example "Hiring process of new Flatguide in the area is still a work in progress. Should be okay by end of the year"
     */
    optionalComment?: string
  } & {
    state?: FlatsyMissionState
    /** @example "NO_FLATSY_COVERAGE_OF_AREA" */
    reason?:
      | 'DEADLINE_TOO_CLOSE'
      | 'MISSING_OR_INCOMPLETE_MISSION_INFORMATION'
      | 'NO_AVAILABLE_FLATGUIDE'
      | 'NO_FLATSY_COVERAGE_OF_AREA'
      | 'NO_QUALIFIED_FLATGUIDE'
  }
  previousStates?: {
    state: FlatsyMissionState
    /** @example "NO_FLATSY_COVERAGE_OF_AREA" */
    reason?:
      | 'DEADLINE_TOO_CLOSE'
      | 'MISSING_OR_INCOMPLETE_MISSION_INFORMATION'
      | 'NO_AVAILABLE_FLATGUIDE'
      | 'NO_FLATSY_COVERAGE_OF_AREA'
      | 'NO_QUALIFIED_FLATGUIDE'
  }[]
  /** @example "La porte d'entrée est dure à ouvrir." */
  instructions?: string
  tenants?: {
    /** @example "Olivier" */
    firstName?: string
    /** @example "Dupont" */
    lastName?: string
    /** @example "odupont@example.com" */
    email?: string
    /**
     * @maxLength 50
     * @example "+33678901234"
     */
    phoneNumber?: string
    /**
     * Mandatory in tha case of an Inspection of EXIT type
     * @example "52 rue d'aboukir"
     */
    address?: string
    /**
     * Mandatory in tha case of an Inspection of EXIT type
     * @maxLength 50
     * @example "75002"
     */
    postalCode?: string
    /**
     * Mandatory in tha case of an Inspection of EXIT type
     * @maxLength 50
     * @example "Paris"
     */
    locality?: string
  }[]
  landlord?: {
    /** @example "Olivier" */
    firstName?: string
    /** @example "Dupont" */
    lastName?: string
    /** @example "odupont@example.com" */
    email?: string
    /** @example "52 rue d'aboukir" */
    address?: string
    /**
     * @maxLength 50
     * @example "75002"
     */
    postalCode?: string
    /**
     * @maxLength 50
     * @example "Paris"
     */
    locality?: string
  }
  thirdParty?: {
    /** @example "aaabbbccc" */
    id?: string
    /** @example "OK" */
    state?: string
    /**
     * Nockee report has been copied from another Nockee report with this id
     * @example "12345678-5717-4562-b3fc-123456789012"
     */
    copiedFrom?: string
    /**
     * @format date-time
     * @example "2023-12-30T10:00:00+02:00"
     */
    completedAt?: string
  }
}

/** Mission of type Inspection */
export type FlatsyProMissionInspection = FlatsyProMission &
  FlatsyPublicMissionInspection & {
    inspection?: {
      /**
       * TBD
       * @example 10
       */
      daysForward?: number
      /**
       * TBD
       * @example 90
       */
      maxViewingsSlots?: number
      /**
       * TBD
       * @example 10
       */
      maxViewingsPerDay?: number
      /**
       * Email address where the Inspection result should be sent
       * @example "odupont@example.com"
       */
      recipientEmail?: string
      /**
       * Whether the booking slot should be selected by the Customer or the tenant himself
       * @default "CUSTOMER"
       * @example "CUSTOMER"
       */
      bookingSelectionMode?: 'CUSTOMER' | 'TENANT' | 'FLATSY'
      /**
       * The report produced at the end of the Inspection should be branded using the Customer logo, adress and legal information.
       * @default false
       */
      requiresBrandedInspectionReport?: boolean
      tool?: {
        /**
         * The Inspection tool the Customer requires the Flatguide to use for the Inspection
         * @default "NOCKEE"
         * @example "NOCKEE"
         */
        default?: 'NOCKEE' | 'ELYPSO' | 'PAPER'
        /**
         * The Inspection tool to use should the InspectionTool is unavailable
         * @default "NOCKEE"
         * @example "PAPER"
         */
        fallback?: 'NOCKEE' | 'PAPER'
      }
    }
    property?: {
      location?: {
        /**
         * Latitude of the Property
         * @example 12.4
         */
        latitude: number
        /**
         * Longitude of the Property
         * @example 12.4
         */
        longitude: number
        /**
         * ISO 3166-1 alpha-3 code of the country.
         * @example "FRA"
         */
        countryCode:
          | 'ABW'
          | 'AFG'
          | 'AGO'
          | 'AIA'
          | 'ALA'
          | 'ALB'
          | 'AND'
          | 'ARE'
          | 'ARG'
          | 'ARM'
          | 'ASM'
          | 'ATA'
          | 'ATF'
          | 'ATG'
          | 'AUS'
          | 'AUT'
          | 'AZE'
          | 'BDI'
          | 'BEL'
          | 'BEN'
          | 'BES'
          | 'BFA'
          | 'BGD'
          | 'BGR'
          | 'BHR'
          | 'BHS'
          | 'BIH'
          | 'BLM'
          | 'BLR'
          | 'BLZ'
          | 'BMU'
          | 'BOL'
          | 'BRA'
          | 'BRB'
          | 'BRN'
          | 'BTN'
          | 'BVT'
          | 'BWA'
          | 'CAF'
          | 'CAN'
          | 'CCK'
          | 'CHE'
          | 'CHL'
          | 'CHN'
          | 'CIV'
          | 'CMR'
          | 'COD'
          | 'COG'
          | 'COK'
          | 'COL'
          | 'COM'
          | 'CPV'
          | 'CRI'
          | 'CUB'
          | 'CUW'
          | 'CXR'
          | 'CYM'
          | 'CYP'
          | 'CZE'
          | 'DEU'
          | 'DJI'
          | 'DMA'
          | 'DNK'
          | 'DOM'
          | 'DZA'
          | 'ECU'
          | 'EGY'
          | 'ERI'
          | 'ESH'
          | 'ESP'
          | 'EST'
          | 'ETH'
          | 'FIN'
          | 'FJI'
          | 'FLK'
          | 'FRA'
          | 'FRO'
          | 'FSM'
          | 'GAB'
          | 'GBR'
          | 'GEO'
          | 'GGY'
          | 'GHA'
          | 'GIB'
          | 'GIN'
          | 'GLP'
          | 'GMB'
          | 'GNB'
          | 'GNQ'
          | 'GRC'
          | 'GRD'
          | 'GRL'
          | 'GTM'
          | 'GUF'
          | 'GUM'
          | 'GUY'
          | 'HKG'
          | 'HMD'
          | 'HND'
          | 'HRV'
          | 'HTI'
          | 'HUN'
          | 'IDN'
          | 'IMN'
          | 'IND'
          | 'IOT'
          | 'IRL'
          | 'IRN'
          | 'IRQ'
          | 'ISL'
          | 'ISR'
          | 'ITA'
          | 'JAM'
          | 'JEY'
          | 'JOR'
          | 'JPN'
          | 'KAZ'
          | 'KEN'
          | 'KGZ'
          | 'KHM'
          | 'KIR'
          | 'KNA'
          | 'KOR'
          | 'KWT'
          | 'LAO'
          | 'LBN'
          | 'LBR'
          | 'LBY'
          | 'LCA'
          | 'LIE'
          | 'LKA'
          | 'LSO'
          | 'LTU'
          | 'LUX'
          | 'LVA'
          | 'MAC'
          | 'MAF'
          | 'MAR'
          | 'MCO'
          | 'MDA'
          | 'MDG'
          | 'MDV'
          | 'MEX'
          | 'MHL'
          | 'MKD'
          | 'MLI'
          | 'MLT'
          | 'MMR'
          | 'MNE'
          | 'MNG'
          | 'MNP'
          | 'MOZ'
          | 'MRT'
          | 'MSR'
          | 'MTQ'
          | 'MUS'
          | 'MWI'
          | 'MYS'
          | 'MYT'
          | 'NAM'
          | 'NCL'
          | 'NER'
          | 'NFK'
          | 'NGA'
          | 'NIC'
          | 'NIU'
          | 'NLD'
          | 'NOR'
          | 'NPL'
          | 'NRU'
          | 'NZL'
          | 'OMN'
          | 'PAK'
          | 'PAN'
          | 'PCN'
          | 'PER'
          | 'PHL'
          | 'PLW'
          | 'PNG'
          | 'POL'
          | 'PRI'
          | 'PRK'
          | 'PRT'
          | 'PRY'
          | 'PSE'
          | 'PYF'
          | 'QAT'
          | 'REU'
          | 'ROU'
          | 'RUS'
          | 'RWA'
          | 'SAU'
          | 'SDN'
          | 'SEN'
          | 'SGP'
          | 'SGS'
          | 'SHN'
          | 'SJM'
          | 'SLB'
          | 'SLE'
          | 'SLV'
          | 'SMR'
          | 'SOM'
          | 'SPM'
          | 'SRB'
          | 'SSD'
          | 'STP'
          | 'SUR'
          | 'SVK'
          | 'SVN'
          | 'SWE'
          | 'SWZ'
          | 'SXM'
          | 'SYC'
          | 'SYR'
          | 'TCA'
          | 'TCD'
          | 'TGO'
          | 'THA'
          | 'TJK'
          | 'TKL'
          | 'TKM'
          | 'TLS'
          | 'TON'
          | 'TTO'
          | 'TUN'
          | 'TUR'
          | 'TUV'
          | 'TWN'
          | 'TZA'
          | 'UGA'
          | 'UKR'
          | 'UMI'
          | 'URY'
          | 'USA'
          | 'UZB'
          | 'VAT'
          | 'VCT'
          | 'VEN'
          | 'VGB'
          | 'VIR'
          | 'VNM'
          | 'VUT'
          | 'WLF'
          | 'WSM'
          | 'YEM'
          | 'ZAF'
          | 'ZMB'
          | 'ZWE'
        /**
         * Mandatory.
         * @maxLength 50
         * @example "75002"
         */
        postalCode: string
        /**
         * @maxLength 50
         * @example "Paris"
         */
        locality: string
        /**
         * Street of the property (if available)
         * @example "rue d'Aboukir"
         */
        route?: string
        /**
         * Full Street address (Number + route) of the property
         * @example "50 rue d'Aboukir"
         */
        streetAddress?: string
        /**
         * @maxLength 10
         * @example "50"
         */
        streetNumber?: string
        /**
         * Optional. numbered/lettered entrance / Mailbox.
         * @maxLength 10
         * @example "bte 2"
         */
        entrance?: string
        /**
         * Indicates the name of the property, n apartment, unit, office, lot, or other secondary unit designator.
         * @maxLength 50
         * @example "Résidence les platanes"
         */
        unit?: string
        /**
         * Indicates the number or ref of the door of the property within the building.
         * @maxLength 50
         * @example "2F"
         */
        door?: string
        /**
         * Optional. Indicates the neighborhood or the locality naming the property.
         * @maxLength 50
         * @example "Dumonceau"
         */
        placeName?: string
        /**
         * Number of the floor in the building; 0 means ground floor, NOT first floor; 1 means first upper floor; -1 means first basement floor Mandatory for APARTMENTS. Optional for OFFICE, INDUSTRY, TRADING, GASTRONOMY_HOTEL,GARAGE. Otherwise, the information will be discarded.
         * @min -5
         * @max 999
         * @example 3
         */
        floorNumber?: number
        /** @example "B1" */
        zoneABC?: string
        /** @example "1" */
        zone123?: string
      }
      /**
       * Indicates the type of the property.
       * @example "HOUSE"
       */
      estateType?:
        | 'HOUSE'
        | 'APARTMENT'
        | 'OFFICE'
        | 'PARKING'
        | 'MISCELLANEOUS'
        | 'STORAGE_PRODUCTION'
        | 'PLOT'
        | 'AGRICULTURE_FORESTRY'
        | 'TRADING'
        | 'GASTRONOMY_HOTEL'
        | 'SENIOR'
        | 'PROJECT'
        | 'HOMES_TO_BUILD'
      /** Contains different space types for a property. You can submit multiple spaces here (e.g. livingSpace, atticSpace and gardenSpace) */
      spaces?: {
        /** TBD */
        residential?: {
          /**
           * Indicates the surface of the living area in sqm.
           * @min 1
           * @example 120.25
           */
          livingSpace?: number
          /**
           * Used if the surface is not the right format when sent to our system.
           * @example "120,25 m2"
           */
          livingSpaceLegacy?: string
          /**
           * Indicates the surface of the garden in sqm. Can be set only if features.hasGarden is true.
           * @min 1
           * @example 300
           */
          gardenSpace?: number
          /**
           * Indicates the surface of the terrace in sqm. Can be set only if features.hasTerrace is true.
           * @min 1
           * @example 20
           */
          terraceSpace?: number
          /**
           * Indicates the surface of the terrace in sqm. Can be set only if features.hasBalcony is true.
           * @min 1
           * @example 20
           */
          balconySpace?: number
          /**
           * Indicates the surface of the rofftop in sqm. Can be set only if features.hasRooftop is true.
           * @min 1
           * @example 20
           */
          rooftopSpace?: number
          /**
           * Indicates the surface of the terrace in sqm. Can be set only if structure.building.hasCellar is true.
           * @min 1
           * @example 20
           */
          cellarSpace?: number
        }
        /** TBD */
        commercial?: {
          /**
           * Indicates the surface of the office in sqm. Can be set only if structure.building.office is YES. Optional for HOUSE, OFFICE, STORAGE_PRODUCTION, TRADING, GASTRONOMY_HOTEL, APARTMENT, SENIOR, MISCELLANEOUS, HOMES_TO_BUILD. Otherwise, the information will be discarded.
           * @min 1
           * @example 100
           */
          officeSpace?: number
          /**
           * Indicates the surface of the showroom in sqm. Optional for OFFICE, STORAGE_PRODUCTION, TRADING, GASTRONOMY_HOTEL. Otherwise, the information will be discarded.
           * @min 0
           * @example 500
           */
          showroomSpace?: number
        }
        plot?: {
          /**
           * Indicates the depth of the land in meters.
           * @min 1
           * @example 300
           */
          depth?: number
          /**
           * Indicates the width of the land in meters.
           * @min 1
           * @example 25
           */
          front?: number
          /**
           * Indicates the total surface of your plot, including the space taken up by any constructions, in sqm.
           * @min 1
           * @example 500
           */
          space?: number
        }
      }
      /** TBD */
      structure?: {
        /** Numbers of various roms in the property */
        rooms?: {
          /**
           * number of bathRooms available in the property. Floating point numbers may also be used here, because half bathRoomsrooms are possible.
           * @min 0
           * @example 2
           */
          numberOfBathrooms?: number
          /**
           * number of bedrooms.
           * @min 0
           * @example 1
           */
          numberOfBedrooms?: number
          /**
           * number of rooms available in the property. Floating point numbers may also be used here, because half rooms are possible.
           * @min 0
           * @example 2
           */
          numberOfRooms?: number
        }
        /** TBD */
        building?: {
          /**
           * The total number of floors of the building where the propoerty sits.
           * @min 1
           * @max 999
           * @example 7
           */
          numberOfFloors?: number
          /**
           * The total number of properties in the building where the propoerty sits in the case of co-wonership.
           * @min 1
           * @max 999
           * @example 7
           */
          totalNumberOfProperties?: number
          /**
           * The number of additional buildings included in the property, which could include a garage, a poolhouse, stables, etc.
           * @min 0
           */
          numberOfAnnexBuildings?: number
          /**
           * Indicates if the property contains an attic. An attic is a space or room inside or partly inside the roof of a building.
           * @example true
           */
          hasAttic?: boolean
          /**
           * Indicates the orientation of the property itself.
           * @example "SOUTH"
           */
          propertyOrientation?:
            | 'NORTH'
            | 'SOUTH'
            | 'EAST'
            | 'WEST'
            | 'NORTH_EAST'
            | 'NORTH_WEST'
            | 'SOUTH_EAST'
            | 'SOUTH_WEST'
          /**
           * Indicates the orientation of the terrace. Can be set only if features.terrace is true.
           * @example "SOUTH"
           */
          terraceOrientation?:
            | 'NORTH'
            | 'SOUTH'
            | 'EAST'
            | 'WEST'
            | 'NORTH_EAST'
            | 'NORTH_WEST'
            | 'SOUTH_EAST'
            | 'SOUTH_WEST'
          /**
           * Indicates the orientation of the garden. Can be set only if features.garden is true.
           * @example "SOUTH"
           */
          gardenOrientation?:
            | 'NORTH'
            | 'SOUTH'
            | 'EAST'
            | 'WEST'
            | 'NORTH_EAST'
            | 'NORTH_WEST'
            | 'SOUTH_EAST'
            | 'SOUTH_WEST'
          /**
           * Indicates if an elevator is available.
           * @example true
           */
          hasElevator?: boolean
          kitchen?: {
            equipment?: 'NONE' | 'STORAGE' | 'SEMI_EQUIPPED' | 'FULLY_EQUIPPED'
            /** an open-concept kitchen without walls separating it from the rest of the property. */
            isOpen?: boolean
          }
          withView?: {
            /**
             * with a view on the sea
             * @example false
             */
            seaView?: boolean
            /**
             * with a view on a monument
             * @example false
             */
            monumentView?: boolean
          }
          /**
           * Indicates if the property contains a dining room. Optional for HOUSE, APARTMENT, SENIOR, MISCELLANEOUS, HOMES_TO_BUILD. Otherwise, the information will be discarded.
           * @example true
           */
          hasDiningRoom?: boolean
          /**
           * Indicates if the property contains a living room.
           * @example true
           */
          hasLivingRoom?: boolean
          /**
           * Indicates if the property contains a workspace.
           * @example true
           */
          hasWorkspace?: boolean
          /**
           * Indicates if the property contains an office.
           * @example true
           */
          hasOffice?: boolean
          /**
           * Indicates if the property contains a cellar(basement). The cellar is the floor of a building which is partly or entirely below ground level.
           * @example true
           */
          hasCellar?: boolean
          /**
           * Indicates if the property contains a dressing room.
           * @example true
           */
          hasDressingRoom?: boolean
          /**
           * Indicates whether the property has an outdoor parking lot.
           * @example true
           */
          hasOutdoorParking?: boolean
          /**
           * Indicates whether the property has an (indoor) garage.
           * @example true
           */
          hasGarage?: boolean
          /**
           * Indicates whether the property has a box.
           * @example true
           */
          hasBox?: boolean
          /**
           * Indicates whether the property has a mailbox.
           * @example true
           */
          hasMailbox?: boolean
          /** Cellar details */
          cellar?: {
            /**
             * Number of cellars
             * @min 0
             * @example 1
             */
            count?: number
            /**
             * Code/number or location of the cellar(s)
             * @example "15b"
             */
            codeOrLocation?: string
          }
          /** Box details */
          box?: {
            /**
             * Number of boxes
             * @min 0
             * @example 2
             */
            count?: number
            /**
             * Code/number or location of the box(es)
             * @example "N°12,13"
             */
            codeOrLocation?: string
          }
          /** Outdoor parking details */
          outdoorParking?: {
            /**
             * Number of outdoor parking lots
             * @min 0
             * @example 1
             */
            count?: number
            /**
             * Code/number or location of the parking(s)
             * @example "15b"
             */
            codeOrLocation?: string
          }
          /** Garage details */
          garage?: {
            /**
             * Number of indoor garage
             * @min 0
             * @example 1
             */
            count?: number
            /**
             * Code/number or location of the garage(s)
             * @example "3e garage à droite"
             */
            codeOrLocation?: string
          }
          /** Mailbox details */
          mailbox?: {
            /**
             * Number of mailboxes
             * @min 0
             * @example 1
             */
            count?: number
            /**
             * Code/number or location of the mailbox(es)
             * @example 12
             */
            codeOrLocation?: string
          }
        }
        /** Number of parking spots available on the property */
        parkingLots?: {
          /**
           * Indicates the number of outdoor parking spaces
           * @min 0
           */
          outside?: number
          /**
           * Indicates the number of indoor parking spaces.
           * @min 0
           */
          garage?: number
        }
      }
      publication?: {
        /** @maxLength 30 */
        reference: string
        additionalReferences?: string[]
        /** Urls of the classified representing the property on various portals */
        portalUrls?: {
          name:
            | 'APIMO'
            | 'AVENDREALOUER'
            | 'BELLESDEMEURES'
            | 'BIENICI'
            | 'CENTURY21'
            | 'FACEBOOKMARKETPLACE'
            | 'FONCIA'
            | 'HEKTOR'
            | 'IMMONOT'
            | 'IMMOREGION'
            | 'IMMOTOP'
            | 'IMMOWEB'
            | 'JINKA'
            | 'JSONEMAIL'
            | 'LAFORET'
            | 'LEBONCOIN'
            | 'LEFIGARO'
            | 'LOGICIMMO'
            | 'MAILJET'
            | 'MEILLEURSAGENTS'
            | 'NETTY'
            | 'ORPI'
            | 'OTHER'
            | 'OUESTFRANCE'
            | 'PARUVENDU'
            | 'SELOGER'
            | 'SQUAREHABITAT'
            | 'SUPERIMMO'
            | 'UBIFLOW'
          /**
           * Url of the ad on classified portal
           * @format uri
           * @maxLength 500
           */
          url: string | null
        }[]
        /**
         * Determines if the address will be displayed on the frontend. By default the address will be displayed.
         * @default "FULL"
         * @example "STREET_ONLY"
         */
        showAddress?: 'FULL' | 'STREET_ONLY' | 'CITY_ONLY' | 'NO'
        texts?: {
          /** Headline of the classified. The information can be transmitted in several languages and is required for at least one of the languages. */
          headline?: {
            /**
             * @maxLength 60
             * @example "french title"
             */
            fr?: string
            /**
             * @maxLength 60
             * @example "english title"
             */
            en?: string
          }
          /** Description of the classified. The information can be transmitted in several languages and is required for at least one of the languages. */
          description?: {
            /**
             * @maxLength 1500
             * @example "french description"
             */
            fr?: string
            /**
             * @maxLength 1500
             * @example "english description"
             */
            en?: string
          }
        }
      } & {
        /**
         * Property publication status
         * @default "STANDBY"
         */
        state?: 'AVAILABLE' | 'UNAVAILABLE' | 'STANDBY' | 'IDLE'
        /** @format date-time */
        stateLastUpdatedAt?: string
        /** @format string */
        reasonForLastStateUpdate?: string
      }
      /** Information about specific feature of the property */
      features?: {
        /**
         * Indicates if there is air conditioning.
         * @example true
         */
        hasAirConditioning?: boolean
        /**
         * Indicates if the property is furnished.
         * @example true
         */
        isFurnished?: boolean
        /** TBD */
        security?: {
          /**
           * Indicates the presence of an intruder alarm system.
           * @example true
           */
          hasIntruderAlarm?: boolean
          /**
           * Indicates the presence of an high security entrance door.
           * @example true
           */
          hasReinforcedDoor?: boolean
          /** Indicates the presence of system that allows residents to talk to visitors (and possibly see them on video) before granting them access to the building. */
          buildingIntercom?: 'NONE' | 'AUDIO' | 'VIDEO'
        }
        /**
         * Indicates if a building is ready for wheelchair use. When it is barrierFree and the doors/passages are at least 90 cm wide
         * @example true
         */
        isWheelchairAccessible?: boolean
        /**
         * Indicates the presence of a receptionist/concierge in the building.
         * @example true
         */
        hasReceptionist?: boolean
        /** TBD */
        residential?: {
          /**
           * Indicates the presence of a sauna, or steam room.
           * @example true
           */
          hasSauna?: boolean
          /**
           * Indicates the presence of a garage where bikes can be locked on the property.
           * @example true
           */
          hasBikeGarage?: boolean
          /**
           * Indicates the presence of a swimmingPool belonging to the property.
           * @example true
           */
          hasSwimmingPool?: boolean
          /**
           * Indicates the presence of a jacuzzi, hot tub. Optional for HOUSE, APARTMENT, SENIOR, MISCELLANEOUS, HOMES_TO_BUILD. Otherwise, the information will be discarded.
           * @example true
           */
          hasJacuzzi?: boolean
          /**
           * Indicates the presence of a wash or dryroom.
           * @example true
           */
          hasLaundryRoom?: boolean
          /**
           * Indicates the number of fireplaces.
           * @min 1
           * @example 2
           */
          numberOfFireplaces?: number
          /**
           * Indicates if there is a connection to the Cable TV.
           * @example true
           */
          hasCableTv?: boolean
        }
        /**
         * Indicates if there is a connection high speed internet (excluding fiber).
         * @example true
         */
        hasHighSpeedInternetAccess?: boolean
        /**
         * Indicates if there is a optical fiber access to the internet.
         * @example true
         */
        hasFiberInternetAccess?: boolean
        /**
         * Indicates if there's a private garden
         * @example true
         */
        hasGarden?: boolean
        /**
         * Indicates if there is a terrace.
         * @example true
         */
        hasTerrace?: boolean
        /**
         * Indicates if there is a balcony.
         * @example true
         */
        hasBalcony?: boolean
        /**
         * Indicates if there is a rooftop.
         * @example true
         */
        hasRooftop?: boolean
      }
      /** Defines elements of energy source and how it is used. */
      energy?: {
        /** Raw materials, substances and sources used for the production of heat. Multiple answers are possible. */
        heatingSource?: {
          /** @example false */
          isCoal?: boolean
          /** @example true */
          isDistrictHeating?: boolean
          /** @example false */
          isElectric?: boolean
          /** @example true */
          isGas?: boolean
          /** @example false */
          isOil?: boolean
          /** @example false */
          isSolarHeat?: boolean
          /** @example false */
          isSolarPower?: boolean
          /** @example true */
          isWood?: boolean
          /** @example false */
          isWoodPellet?: boolean
        }
        /** Raw materials, substances and sources used for the production of heat. Multiple answers are possible. */
        hotWaterSource?: {
          /** @example false */
          isCoal?: boolean
          /** @example true */
          isDistrictHotWater?: boolean
          /** @example false */
          isElectric?: boolean
          /** @example true */
          isGas?: boolean
          /** @example false */
          isOil?: boolean
          /** @example false */
          isSolarHeat?: boolean
          /** @example false */
          isSolarPower?: boolean
          /** @example false */
          isWood?: boolean
          /** @example false */
          isWoodPellet?: boolean
        }
        /** Belgium energy certificate block. */
        certificate?: {
          /**
           * The primary energy consumption in kWh per m² per year. This is the overall theoretical consumption divided by the heated floor area. It is this value that is characterized by a label.
           * @example 448
           */
          primaryEnergyConsumption?: number
          /**
           * Indicates the Energy Letter according to the Official Regulations
           * @example "A"
           */
          efficiencyClass?:
            | 'A++'
            | 'A+'
            | 'A'
            | 'B'
            | 'C'
            | 'D'
            | 'E'
            | 'F'
            | 'G'
            | 'A+_A++'
            | 'A_A++'
            | 'A_A+'
            | 'B_A++'
            | 'B_A+'
            | 'B_A'
            | 'C_A++'
            | 'C_A+'
            | 'C_A'
            | 'C_B'
            | 'D_A++'
            | 'D_A+'
            | 'D_A'
            | 'D_B'
            | 'D_C'
            | 'E_A++'
            | 'E_A+'
            | 'E_A'
            | 'E_B'
            | 'E_C'
            | 'E_D'
            | 'F_A++'
            | 'F_A+'
            | 'F_A'
            | 'F_B'
            | 'F_C'
            | 'F_D'
            | 'F_E'
            | 'G_A++'
            | 'G_A+'
            | 'G_A'
            | 'G_B'
            | 'G_C'
            | 'G_D'
            | 'G_E'
          /**
           * GreenHouse Gas emissions in Kg CO2eq/m²/year Carbon dioxide equivalent (CO2e or CO2eq or CO2-e) is calculated from global warming potential.
           * @min 1
           * @example 90
           */
          GHGEmission?: number
          /** The notes should not contain the reference number of EPC report nor the EPC score. */
          notes?: {
            /**
             * @maxLength 750
             * @example "french notes"
             */
            fr?: string
            /**
             * @maxLength 750
             * @example "english notes"
             */
            en?: string
          }
        }
      }
    }
    medias?: FlatsyMissionInspectionMedia
    operations?: FlatsyProMissionOperations
  }

export type FlatsyProMissionCommercialViewingBuy = FlatsyProMission &
  FlatsyPublicMissionCommercialViewingBuy & {
    medias?: FlatsyMissionInspectionMedia
    commercialViewing?: {
      /**
       * How many hours in advance at minimum a visit can be booked
       * @example 6
       */
      minAnticipationInHours?: number
      /**
       * The week rush hours for this property
       * @example [12,17,18]
       */
      weekRushHours?: number[]
      /**
       * The week-end rush hours for this property
       * @example [11,12,13]
       */
      weekendRushHours?: number[]
      /**
       * How many visitors are allowrd for a visit
       * @example 1
       */
      maxVisitorsPerViewing?: number
      /**
       * How many negative report are needed before bookings for this propoerty are automaticcaly put on hold
       * @example 3
       */
      negativeFeedbacksBeforeStandBy?: number
      /**
       * TBD
       * @example 40
       */
      tenantWarningDelayInHours?: number
      /**
       * TBD
       * @example 10
       */
      daysForward?: number
      /**
       * TBD
       * @example 90
       */
      maxViewingsSlots?: number
      /**
       * TBD
       * @example 10
       */
      maxViewingsPerDay?: number
    }
  }

export type FlatsyProMissionCommercialViewingRent = FlatsyProMission &
  FlatsyPublicMissionCommercialViewingRent & {
    medias?: FlatsyMissionInspectionMedia
    commercialViewing?: {
      /**
       * How many hours in advance at minimum a visit can be booked
       * @example 6
       */
      minAnticipationInHours?: number
      /**
       * The week rush hours for this property
       * @example [12,17,18]
       */
      weekRushHours?: number[]
      /**
       * The week-end rush hours for this property
       * @example [11,12,13]
       */
      weekendRushHours?: number[]
      /**
       * How many visitors are allowrd for a visit
       * @example 1
       */
      maxVisitorsPerViewing?: number
      /**
       * How many negative report are needed before bookings for this propoerty are automaticcaly put on hold
       * @example 3
       */
      negativeFeedbacksBeforeStandBy?: number
      /**
       * TBD
       * @example 40
       */
      tenantWarningDelayInHours?: number
      /**
       * TBD
       * @example 10
       */
      daysForward?: number
      /**
       * TBD
       * @example 90
       */
      maxViewingsSlots?: number
      /**
       * TBD
       * @example 10
       */
      maxViewingsPerDay?: number
      prequalification?: {
        name?: string
        label?: string
        solvency?: {
          /** @example 1.37 */
          applicantRatio?: number
          /** @example 1.37 */
          guarantorRatio?: number
          /** @example false */
          isRequiredForBothGuarantorAndApplicant?: boolean
          /** @example false */
          areFinancialAllowancesIncludedWithMonthlyIncome?: boolean
          /** @example "PINEL" */
          taxIncomeCeilingRule?: string
        }
        activities?: {
          student?: FlatsyApplicationMissionActivityDetails
          permanentContract?: FlatsyApplicationMissionActivityDetails
          permanentContractOnProbation?: FlatsyApplicationMissionActivityDetails
          fixedTermContract?: FlatsyApplicationMissionActivityDetails
          civilServant?: FlatsyApplicationMissionActivityDetails
          selfEmployed?: FlatsyApplicationMissionActivityDetails
          liberalProfession?: FlatsyApplicationMissionActivityDetails
          craftPerson?: FlatsyApplicationMissionActivityDetails
          ceo?: FlatsyApplicationMissionActivityDetails
          retired?: FlatsyApplicationMissionActivityDetails
          unemploymentBeneficiary?: FlatsyApplicationMissionActivityDetails
          wellfareBeneficiary?: FlatsyApplicationMissionActivityDetails
          noActivity?: FlatsyApplicationMissionActivityDetails
        }
        acceptedGuarantorTypes?: 'FAMILY' | 'FRIEND' | 'MORAL_GUARANTOR' | 'VISALE_GUARANTEE'
      }
    }
  }

/** Mission CommercialViewing record */
export type FlatsyProMissionCommercialViewing =
  | FlatsyProMissionCommercialViewingBuy
  | FlatsyProMissionCommercialViewingRent

export type FlatsySystemMissionCommercialViewingBuy = FlatsyProMissionCommercialViewingBuy & {
  operations?: FlatsyMissionOperations
}

export type FlatsySystemMissionCommercialViewingRent = FlatsyProMissionCommercialViewingRent & {
  operations?: FlatsyMissionOperations
}

/** Mission CommercialViewing record */
export type FlatsySystemMissionCommercialViewing =
  | FlatsySystemMissionCommercialViewingBuy
  | FlatsySystemMissionCommercialViewingRent

/** Mission of type Inspection */
export type FlatsySystemMissionInspection = FlatsyProMissionInspection & {
  operations?: FlatsyMissionOperations
}

/** Template from third party tool */
export interface FlatsyAdminThirdPartyTemplate {
  /**
   * Id of third party template
   * @format uuid
   */
  id?: string
  /**
   * Label used by frontend for admin users
   * @example "T5_meublé_3CH_2SDB_FLAT"
   */
  displayName?: string
}
