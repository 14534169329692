import React, { FC } from 'react'

import { IconProps } from './Type'
import { createProps } from './Constant'

const ContactSupport: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...createProps({ ...props, settings: { noStroke: true } })}
    >
      <mask id="mask0_8072_2960" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8072_2960)">
        <path
          d="M12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22ZM9.6 19.45L10.8 16.7C10.1 16.45 9.49583 16.0625 8.9875 15.5375C8.47917 15.0125 8.08333 14.4 7.8 13.7L5.05 14.85C5.43333 15.9167 6.025 16.85 6.825 17.65C7.625 18.45 8.55 19.05 9.6 19.45ZM7.8 10.3C8.08333 9.6 8.47917 8.9875 8.9875 8.4625C9.49583 7.9375 10.1 7.55 10.8 7.3L9.65 4.55C8.58333 4.95 7.65 5.55 6.85 6.35C6.05 7.15 5.45 8.08333 5.05 9.15L7.8 10.3ZM12.5 15C13.3333 15 14.0417 14.7083 14.625 14.125C15.2083 13.5417 15.5 12.8333 15.5 12C15.5 11.1667 15.2083 10.4583 14.625 9.875C14.0417 9.29167 13.3333 9 12.5 9C11.6667 9 10.9583 9.29167 10.375 9.875C9.79167 10.4583 9.5 11.1667 9.5 12C9.5 12.8333 9.79167 13.5417 10.375 14.125C10.9583 14.7083 11.6667 15 12.5 15ZM15.4 19.45C16.45 19.05 17.3708 18.4542 18.1625 17.6625C18.9542 16.8708 19.55 15.95 19.95 14.9L17.2 13.7C16.95 14.4 16.5667 15.0042 16.05 15.5125C15.5333 16.0208 14.9333 16.4167 14.25 16.7L15.4 19.45ZM17.2 10.25L19.95 9.1C19.55 8.05 18.9542 7.12917 18.1625 6.3375C17.3708 5.54583 16.45 4.95 15.4 4.55L14.25 7.35C14.9333 7.6 15.525 7.97917 16.025 8.4875C16.525 8.99583 16.9167 9.58333 17.2 10.25Z"
          fill="#727379"
        />
      </g>
    </svg>
  )
}

export default ContactSupport
