import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => {
  return {
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    image: {
      width: '80%',
      maxWidth: 848,
      marginBottom: 57,
    },
    title: {
      marginBottom: 69,
      fontWeight: 'bold',
      fontSize: 104,
      lineHeight: '24px',
      color: '#001345',
    },
  }
})
