import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => {
  return {
    alert: {
      opacity: 0,
      transform: 'translateY(100%)',
      pointerEvents: 'none',
    },
  }
})
