import { PROPERTY_BASE_URL } from '../../constants'
import { Admin } from './Admin'
import { Pro } from './Pro'
import { Public } from './Public'

const configuration = { baseURL: PROPERTY_BASE_URL }

export const FlatsyPublicPropertyClient = new Public(configuration)
export const FlatsyPropertyClient = new Admin(configuration)
export const FlatsyProPropertyClient = (hybridToken: string): Pro => {
  return new Pro({ ...configuration, hybridToken })
}
