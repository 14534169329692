import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  field: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    '& .MuiInputBase-root': {
      backgroundColor: '#FFF',
    },
    '& .field-check': {
      opacity: 0,
      color: theme.palette.success.main,
    },
    '&$field-updated': {
      '& fieldset': {
        borderColor: theme.palette.success.main,
      },
      '& label': {
        color: theme.palette.success.main,
      },
      '& .field-check': {
        opacity: 1,
      },
    },
  },
  helperText: {
    lineHeight: '1.26',
  },
  'field-updated': {},
  'field-error': {
    '& fieldset': {
      borderColor: theme.palette.error.main,
    },
    '& input::placeholder': {
      color: `${theme.palette.error.main} !important`,
      opacity: 1,
    },
  },
}))
