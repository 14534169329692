import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  lineGrey: {
    width: '100%',
    height: 1,
    background: theme.palette.secondary.light,
  },
  containerLine: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}))

export default useStyles
