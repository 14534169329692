import theme from '@flatsy/core/MuiTheme/theme'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 500,
    maxWidth: '100%',
    marginBottom: 100,
    position: 'relative',
    zIndex: 2,
    opacity: 0,
    transform: 'translateY(40px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  innerActive: {
    opacity: 1,
    transform: 'translateY(0px)',
    transition: 'all cubic-bezier(0.290, 0.005, 0.085, 1.000) 1s 0.2s',
  },
  successImage: {
    width: 240,
    maxWidth: '100%',
    height: 'auto',
    marginBottom: '-45px',
    transform: 'scale(0.5)',
    opacity: 0,
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 16,
  },
  border: {
    width: '80%',
    border: `1px solid ${theme.palette.secondary.light}`,
    marginTop: 8,
  },
  button: {
    width: '100%',
    marginTop: 40,
    paddingTop: 8,
    paddingBottom: 8,
    textTransform: 'initial',
  },
  buttonFailed: {
    width: '100%',
    marginTop: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  ballon1: {
    position: 'absolute',
    top: '-10px',
    right: 210,
    zIndex: 1,
    transform: 'translate3d(0, 200%, 0)',
    opacity: 0,
    transition: 'all cubic-bezier(0.290, 0.005, 0.085, 1.000) 1.5s 0.4s',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ballon2: {
    position: 'absolute',
    top: '25%',
    left: 130,
    zIndex: 1,
    opacity: 0,
    transform: 'translate3d(0, 200%, 0)',
    transition: 'all cubic-bezier(0.290, 0.005, 0.085, 1.000) 1.5s 0.6s',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ballon3: {
    position: 'absolute',
    bottom: '20%',
    right: 120,
    zIndex: 1,
    opacity: 0,
    transform: 'translate3d(0, 200%, 0)',
    transition: 'all cubic-bezier(0.290, 0.005, 0.085, 1.000) 1.5s 0.8s',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ballonActive: {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
  },
  successActive: {
    marginBottom: 24,
    transform: 'scale(1)',
    opacity: 1,
    transition: 'all cubic-bezier(0.290, 0.005, 0.085, 1.000) 1s 0.4s',
  },
  container: {
    width: '100%',
  },
  submit: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: 26,
    textTransform: 'initial',
  },
  borderSeparator: {
    width: '60%',
    border: `1px solid ${theme.palette.secondary.light}`,
    margin: 'auto',
    marginBottom: 24,
    marginTop: 24,
  },
  containerGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  containerCardLeft: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 24,
    margin: 8,
  },
  containerCardRight: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    padding: 24,
    margin: 8,
  },
})

export default useStyle
