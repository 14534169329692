import { SubBody3 } from '@flatsy/core/MaterialUi/Typo'
import { format } from '@flatsy/utils/Functions/DateTime/Datefns'
import { DateTime } from '@flatsy/utils/dates'
import { Badge, Box } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Grid } from '@mui/material'
import clsx from 'clsx'
import { add } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../Hooks'
import useStyle from './Styles'
import { DayProps } from './Type'

/**
 * A REFACTO
 */
const Days: React.FC<DayProps> = ({
  slotsByDay,
  index,
  handleClickOnSlot,
  selectedDay,
  startDate,
  screenHeight,
}): React.ReactElement | null => {
  const { numberDays, screenXs } = useUtils()
  const classes = useStyle({ numberDays, screenHeight })
  const { i18n } = useTranslation()
  let slotDay = add(startDate || new Date(), { days: index })
  let slotKeys = format(slotDay, 'P')
  let slot: string[] = slotsByDay[slotKeys]

  if (screenXs) {
    if (slotsByDay[Object.keys(slotsByDay)[index]]) {
      slotKeys = Object.keys(slotsByDay)[index]
      slotDay = new Date(slotsByDay[slotKeys][0])
      slot = slotsByDay[slotKeys]
    } else {
      return null
    }
  }

  const getLineSlot = () => {
    let maxSlot = 0
    let keys
    const slotArray = []

    if (slotsByDay !== undefined) {
      keys = Object.keys(slotsByDay)
    }
    keys &&
      keys.forEach((element) => {
        if (slotsByDay[element] && slotsByDay[element].length > maxSlot) {
          maxSlot = slotsByDay[element].length
        }
      })
    const slotRemaining = slot && maxSlot - slot.length

    if (slotRemaining === undefined) {
      for (let i = 0; i < maxSlot; i++) {
        slotArray.push(i)
      }
    }
    for (let i = 0; i < slotRemaining; i++) {
      slotArray.push(i)
    }

    return slotArray
  }

  return (
    <Grid container direction="row">
      <Grid item xs={12} className={classes.day} justifyContent="center">
        <Box className={classes.header}>
          <span className={classes.dateGrey}>
            {i18n.t('@date_day', { value: DateTime.fromJSDate(slotDay) })}
          </span>
          <span className={classes.dateDark}>{DateTime.fromJSDate(slotDay).day}</span>
          <span className={classes.dateGrey}>
            {i18n.t('@date_month', { value: DateTime.fromJSDate(slotDay) })}
          </span>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.line} />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.slots}>
          {slot?.map((start) => {
            const selected = selectedDay === start
            return (
              <Badge
                key={start}
                invisible={!selected}
                className={classes.badge}
                badgeContent={
                  <CheckCircleIcon
                    style={{
                      width: 20,
                      height: 20,
                      marginTop: 13,
                      marginRight: 7,
                    }}
                  />
                }
              >
                <button
                  type="button"
                  className={clsx(classes.slot, selected && classes.selected)}
                  onClick={() => handleClickOnSlot(start)}
                >
                  <SubBody3 className={classes.slotInner}>
                    {i18n.t('@date_time', { value: DateTime.fromISO(start) })}
                  </SubBody3>
                </button>
              </Badge>
            )
          })}
          {getLineSlot().map(() => {
            return <div className={classes.lineCalendar} />
          })}
        </div>
      </Grid>
    </Grid>
  )
}

export default Days
