import {
  FlatsyMissionType,
  FlatsyPublicMissionInspection,
} from '@flatsy/api/client/3.0.0/services/Mission/data-contracts'
import { DateTime } from '@flatsy/utils/dates'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBookableByMissionId } from 'src/hooks/Booking'
import { useCustomerById } from 'src/hooks/Customer'
import { useMissionForToken } from 'src/hooks/Mission'
import { usePropertyById } from 'src/hooks/Property'
import { ApplicationContextProps } from './Type'

export const ApplicationContext = createContext<ApplicationContextProps>({
  token: '',
  missionQuery: undefined,
  booking: {
    bookable: undefined,
    createBooking: undefined,
    selected: undefined,
    setSelected: () => null,
  },
  startCalendar: undefined,
  setStartCalendar: () => null,
  customer: undefined,
  isInsideDate: true,
  property: undefined,
})

export const ApplicationProvider: React.FC = ({ children }) => {
  const { public_token: token } = useParams<{ public_token: string }>()
  const [startCalendar, setStartCalendar] = useState<string>()
  const [startsAt, setStartsAt] = useState<string>('')
  const [endsAt, setEndsAt] = useState<string>('')
  const missionQuery = useMissionForToken(token)
  const [isInsideDate, setIsInsideDate] = useState<boolean>(true)

  useEffect(() => {
    if (missionQuery.data && missionQuery.data.type === FlatsyMissionType.INSPECTION) {
      const missionData = missionQuery.data as FlatsyPublicMissionInspection
      const endsAt = missionData.inspection?.preferredDates?.endsAt || ''
      const startsAt = missionData.inspection?.preferredDates?.startsAt || ''
      const preferredStartWeek = DateTime.fromISO(startCalendar || startsAt) // startCalendar from handleChange

      const monday = preferredStartWeek.startOf('week').toISODate()
      const sunday = preferredStartWeek.endOf('week').toISODate()

      if (endsAt > monday && endsAt < sunday) {
        setEndsAt(endsAt)
      } else {
        setEndsAt(endsAt < monday ? endsAt : sunday)
      }
      if (startsAt > monday && startsAt < sunday) {
        setStartsAt(startsAt)
      } else {
        setStartsAt(startsAt > sunday ? startsAt : monday)
      }
      setIsInsideDate(
        (endsAt > monday || endsAt > sunday) && (startsAt < monday || startsAt < sunday)
      )
    } else {
      setStartsAt(DateTime.now().toISODate())
      setEndsAt(DateTime.now().plus({ days: 6 }).toISODate())
    }
  }, [missionQuery.data, startCalendar, startsAt])

  const { bookable, createBooking } = useBookableByMissionId(
    token,
    missionQuery?.data?.id || '',
    startsAt,
    endsAt
  )

  const [selected, setSelected] = useState<string>()
  const { data: customer } = useCustomerById(token, missionQuery.data?.customerId || '')
  const { data: property } = usePropertyById(token, missionQuery.data?.propertyId || '')

  const value = useMemo<ApplicationContextProps>(() => {
    return {
      token,
      missionQuery,
      booking: {
        bookable,
        createBooking,
        selected,
        setSelected,
      },
      startCalendar,
      setStartCalendar,
      customer,
      property,
      isInsideDate,
    }
  }, [token, missionQuery, bookable, createBooking, selected, setSelected, isInsideDate])

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>
}
