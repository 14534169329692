import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&$button-updated:disabled': {
      backgroundColor: theme.palette.success.main,
      color: '#FFF',
    },
    '&$button-error:disabled': {
      backgroundColor: theme.palette.error.main,
      color: '#FFF',
    },
  },
  'button-updated': {},
  'button-error': {},
}))
