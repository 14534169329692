/* eslint-disable react-i18n/no-dynamic-translation-keys */
import Separator from '@flatsy/core/Layout/Separator'
import LoaderSmall from '@flatsy/core/Loader/Small'
import { Body1, SubBody1 } from '@flatsy/core/MaterialUi/Typo'
import { floorFormat, surfaceFormat } from '@flatsy/utils/Functions/format/Conversions'
import { Card } from '@material-ui/core'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined'
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import SquareFootIcon from '@material-ui/icons/SquareFoot'
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import { Divider } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Carousel from 'src/components/Carousel'
import { ApplicationContext } from 'src/context/Application'
import { useCustomerById } from 'src/hooks/Customer'
import { usePropertyById } from 'src/hooks/Property'
import { renderCheckItem } from '../../../Utils'
import useStyles from './Style'
import { SidebarProps } from './Type'

const Sidebar: React.FC<SidebarProps> = ({ mission }) => {
  const { token } = useContext(ApplicationContext)
  const { data: property } = usePropertyById(token, mission.propertyId || '')
  const { data: customer } = useCustomerById(token, mission.customerId)
  const classes = useStyles()
  const { i18n } = useTranslation()

  const renderLogo = () => {
    if (customer && customer.design && customer.design.logoUrl) {
      return (
        <div className={classes.containerLogo}>
          <img src={customer.design.logoUrl} alt="Logo customer" className={classes.image} />
        </div>
      )
    }
  }

  const renderCarousel = () => {
    if (
      property &&
      property.media &&
      property.media.pictures &&
      property.media.pictures?.length !== 0
    ) {
      return (
        <>
          <Separator size="24px" />
          <Carousel size="large" property={property} />
        </>
      )
    }
  }

  const renderItem = (icon: React.ReactElement, label: string, text: string) => {
    return (
      <>
        <Separator size="8px" />
        <div className={classes.flexContainer}>
          <div className={classes.flexFurnished}>
            {icon}
            <SubBody1 className={classes.label}>{i18n.t(`${label}`)}</SubBody1>
          </div>
          <SubBody1 className={classes.description}>{i18n.t(`${text}`)}</SubBody1>
        </div>
      </>
    )
  }

  return (
    <Card variant="outlined" className={classes.containerCard}>
      {!property ? (
        <>
          <Separator size="8px" />
          <LoaderSmall />
        </>
      ) : (
        <div className={classes.sidebar}>
          {renderLogo()}
          {renderCarousel()}
          <Separator size="24px" />
          <div className={classes.divider}>
            <Divider style={{ width: '70%' }} />
          </div>
          <Separator size="8px" />
          {property.estateType &&
            renderItem(
              <HomeWorkOutlinedIcon className={classes.icon} />,
              'type',
              property.estateType?.toLocaleLowerCase()
            )}
          <Separator size="8px" />
          <div className={classes.flexContainer}>
            <div className={classes.flexFurnished}>
              <ChairOutlinedIcon className={classes.icon} />
              <SubBody1 className={classes.label}>{i18n.t('furnished')}</SubBody1>
            </div>
            {renderCheckItem(property.features?.isFurnished)}
          </div>
          {(property.spaces?.residential?.livingSpace || 0) > 0 &&
            renderItem(
              <SquareFootIcon className={classes.icon} />,
              'surface',
              surfaceFormat(property.spaces?.residential?.livingSpace || 0)
            )}
          {(property.structure?.rooms?.numberOfRooms || 0) > 0 &&
            renderItem(
              <LayersOutlinedIcon className={classes.icon} />,
              'floor',
              floorFormat(property.location?.floorNumber || 0, true)
            )}
          {(property.structure?.rooms?.numberOfRooms || 0) > 0 &&
            renderItem(
              <DashboardOutlinedIcon className={classes.icon} />,
              property.structure &&
                property.structure.rooms &&
                property.structure.rooms.numberOfRooms &&
                property.structure.rooms.numberOfRooms > 1
                ? 'rooms'
                : 'room',
              (property?.structure?.rooms?.numberOfRooms || 0).toString()
            )}
          {(property.structure?.rooms?.numberOfBedrooms || 0) > 0 &&
            renderItem(
              <HotelOutlinedIcon className={classes.icon} />,
              property.structure &&
                property.structure.rooms &&
                property.structure.rooms.numberOfBedrooms &&
                property.structure.rooms.numberOfBedrooms > 1
                ? 'bedrooms'
                : 'bedroms',
              (property?.structure?.rooms?.numberOfBedrooms || 0).toString()
            )}
          <Separator size="16px" />
          <div className={classes.divider}>
            <Divider style={{ width: '70%' }} />
          </div>
          <Separator size="16px" />
          {property.publication?.showAddress && (
            <div className={classes.flex}>
              <PlaceOutlinedIcon className={classes.icon} />
              <Body1
                className={classes.description}
              >{`${property.location?.streetAddress}, ${property.location?.locality}`}</Body1>
            </div>
          )}
        </div>
      )}
    </Card>
  )
}

export default Sidebar
