import {
  FlatsyMissionType,
  FlatsyPublicMissionInspection,
} from '@flatsy/api/client/3.0.0/services/Mission/data-contracts'
import Separator from '@flatsy/core/Layout/Separator'
import LoaderWrapper from '@flatsy/core/Loader/Wrapper'
import { Body1, Header1 } from '@flatsy/core/MaterialUi/Typo'
import i18n from '@flatsy/utils/translations'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import { Box, List, ListItemText, SwipeableDrawer } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import { Fragment, useContext, useState } from 'react'
import { LineDrawer } from 'src/components/LineDrawer'
import { ApplicationContext } from 'src/context/Application'
import { renderFg } from '../Utils'
import { Bookable } from './Bookable'
import useStyles from './Style'
import { Topbar } from './Topbar'
import { renderCarousel } from './Utils'

export const BookingMobile: React.FC = () => {
  const classes = useStyles()
  const { missionQuery, customer, property } = useContext(ApplicationContext)
  const [state, setState] = useState({
    bottom: false,
  })

  if (
    (missionQuery && missionQuery.isLoading === true) ||
    !missionQuery ||
    !missionQuery.data ||
    missionQuery.data === undefined
  ) {
    return <LoaderWrapper />
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setState({ ...state, bottom: open })
  }

  const drawerInformation = () => (
    <Box role="presentation" height="700px">
      <LineDrawer />
      <List style={{ padding: 0 }}>
        <ListItem key={1} disablePadding>
          {renderCarousel(property)}
        </ListItem>
        <Separator size="16px" />
        <ListItem key={2} disablePadding>
          {property && property.publication?.showAddress && (
            <ListItemText
              primary={
                <div className={classes.flex}>
                  <PlaceOutlinedIcon className={classes.icon} />
                  <Body1
                    className={classes.description}
                  >{`${property.location?.streetAddress}, ${property.location?.locality}`}</Body1>
                </div>
              }
            />
          )}
        </ListItem>
        <Separator size="32px" />
      </List>
    </Box>
  )

  const renderBookable = () => {
    if (
      missionQuery.data &&
      missionQuery.data.id &&
      missionQuery.data.type === FlatsyMissionType.INSPECTION
    ) {
      const missionData = missionQuery.data as FlatsyPublicMissionInspection
      if (
        missionData.inspection &&
        missionData.inspection.preferredDates &&
        missionData.inspection.preferredDates.endsAt &&
        missionData.inspection.preferredDates.startsAt
      ) {
        const { startsAt, endsAt } = missionData.inspection.preferredDates
        return <Bookable missionId={missionQuery.data.id} startsAt={startsAt} endsAt={endsAt} />
      }
    }
  }

  return (
    <div className={classes.fullContainer}>
      <Fragment key="bottom">
        <Topbar toggleDrawer={toggleDrawer} customer={customer} />
        <Separator size="8px" />
        <div className={classes.fullContainer}>
          <Separator size="16px" />
          <Header1 lowerCase className={classes.title}>
            {i18n.t('book_your_inspection_appointment')}
          </Header1>
          <Separator size="8px" />
          <div className={classes.fg}>{renderFg(missionQuery)}</div>
          <div className={classes.containerBookable}>{renderBookable()}</div>
        </div>
        <SwipeableDrawer
          anchor="bottom"
          open={state.bottom}
          onOpen={toggleDrawer(true)}
          onClose={toggleDrawer(false)}
          className={classes.swipeable}
        >
          {drawerInformation()}
        </SwipeableDrawer>
      </Fragment>
    </div>
  )
}
