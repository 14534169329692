import { FlatsyPublicPropertyClient } from '@flatsy/api/client/3.0.0/services/Property'
import { FlatsyPublicProperty } from '@flatsy/api/client/3.0.0/services/Property/data-contracts'
import { UseQueryResult, useQuery } from 'react-query'

export const usePropertyById = (
  publicToken: string,
  propertyId: string
): UseQueryResult<FlatsyPublicProperty> => {
  const property = useQuery({
    queryKey: ['property', propertyId],
    queryFn: () => {
      return FlatsyPublicPropertyClient.propertyDetail(propertyId, {
        headers: {
          Authorization: `Bearer ${publicToken}`,
          Accept: '*/*',
        },
      }).then((response) => {
        return response.data
      })
    },
    keepPreviousData: true,
    structuralSharing: true,
    cacheTime: 10 * 60,
    enabled: propertyId !== undefined && propertyId !== '',
  })
  return property
}
