import { Interval, DateTime } from 'luxon'

export { DateTime }

export const getHoursAndMinutes = (start: string, end: string, interval = 60) => {
  const timeInterval = Interval.fromDateTimes(
    DateTime.fromISO(`2023-04-07T${start}`),
    DateTime.fromISO(`2023-04-07T${end}`)
  )
  const timeRange: { hour: number; minute: number }[] = []
  for (let i = timeInterval.start.hour; i <= timeInterval.end.hour + 1; i++) {
    for (let j = 0; j < 60; j += interval) {
      const time = DateTime.fromObject({ hour: i, minute: j })
      const timeString = time.toISOTime({
        includeOffset: false,
        includePrefix: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
      })
      if (timeInterval.contains(DateTime.fromISO(`2023-04-07T${timeString}:00`))) {
        timeRange.push({ hour: i, minute: j })
      }
    }
  }
  return timeRange
}
