import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties, CSSProperties } from '@material-ui/styles'

const createZIndex = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const base: CSSProperties | CreateCSSProperties<{}> = {}
  let zIndexValue = 100

  for (let index = 0; index < 100; index++) {
    base[`&:nth-child(${index})`] = { zIndex: zIndexValue }
    zIndexValue -= 1
  }
  return base
}

const useStyle = makeStyles(() => {
  return {
    avatar: {
      // marginRight: '-14px',
      width: 32,
      height: 32,
      border: '2px solid white',
      fontSize: '1rem',
      ...createZIndex(),
      '& .MuiAvatar-img': {
        borderRadius: '50%',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
    },
  }
})

export default useStyle
