import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => {
  return {
    dialog: {
      '& .MuiDialog-paper': {
        overflow: 'hidden!important' as 'hidden',
        [theme.breakpoints.down('xs')]: {
          maxWidth: '100%',
          maxHeight: '100%',
          width: '100%',
          height: '100%',
          margin: 0,
        },
      },
    },
    img: {
      width: '100%',
      height: 612,
      objectFit: 'contain',
      [theme.breakpoints.down('xs')]: {
        width: '100vw',
        height: '100vh',
      },
    },
    mover: {
      display: 'flex',
      gridAutoFlow: 'column',
      justifyContent: 'start',
      transition: 'all ease 0.5s',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
        margin: 0,
      },
    },
    slideModal: {
      position: 'relative',
      display: 'flex',
      background: 'black',
      minWidth: '100%',
    },
    arrowButtonLeft: {
      position: 'absolute',
      top: '50%',
      left: 16,
      transform: 'translate(0%, -50%)',
      padding: 20,
      borderRadius: 16,
      color: 'white',
      background: 'rgba(0, 0, 0, 0.3)',
      transition: 'color ease 0.3s',
      '&:hover': {
        background: theme.palette.primary.light,
        transition: 'color ease 0.3s',
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
          transition: 'color ease 0.3s',
        },
      },
      '&.Mui-disabled': {
        opacity: 0.5,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    arrowButtonRight: {
      position: 'absolute',
      top: '50%',
      right: 16,
      transform: 'translate(0%, -50%)',
      padding: 20,
      borderRadius: 16,
      opacity: 0.8,
      color: 'white',
      background: 'rgba(0, 0, 0, 0.3)',
      transition: 'color ease 0.3s',
      '&:hover': {
        background: theme.palette.primary.light,
        transition: 'color ease 0.3s',
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
          transition: 'color ease 0.3s',
        },
      },
      '&.Mui-disabled': {
        opacity: 0.5,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    arrow: {
      position: 'absolute',
      fontSize: 32,
      color: theme.palette.primary.light,
      transition: 'color ease 0.3s',
    },
    arrowHidden: {
      opacity: 0,
      fontSize: 42,
      padding: 0,
      cursor: 'default',
    },
    cross: {
      position: 'absolute',
      top: 29,
      right: 29,
      color: theme.palette.primary.light,
      [theme.breakpoints.down('xs')]: {
        right: 16,
      },
    },
    nbPicture: {
      position: 'absolute',
      bottom: 24,
      left: 24,
      padding: '5px 10px',
      color: 'white',
      background: 'rgba(0, 0, 0, 0.3)',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
    },
    body1: {
      marginLeft: 10,
      color: theme.palette.primary.light,
    },
  }
})
