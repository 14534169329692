import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  updated: {
    '& fieldset': {
      borderColor: theme.palette.success.main,
    },
    '& label': {
      color: theme.palette.success.main,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.success.main,
    },
  },
}))
