/* eslint-disable @typescript-eslint/ban-types */
import { ButtonProps, Button } from '@material-ui/core'
import clsx from 'clsx'
import { CreateCSSProperties, CSSProperties } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../MuiTheme/theme'
import { ButtonErrorStyles } from './Styles'

/* ---------------------- BUTTON ERROR / SEVERITY HIGH ---------------------- */

export const ButtonError: React.FC<ButtonProps> = ({
  className,
  children,
  ...props
}): React.ReactElement => {
  return (
    <ButtonErrorStyles
      variant="contained"
      color="primary"
      disableElevation
      data-testid="buttonError"
      className={clsx(className)}
      {...props}
    >
      {children}
    </ButtonErrorStyles>
  )
}

/* ------------------------ BUTTON SECONDARY OUTLINED ----------------------- */

export const ButtonSecondaryOutlinedProperties: CSSProperties | CreateCSSProperties<{}> = {
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.secondary.dark}`,
  padding: '8px 16px',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
  color: theme.palette.secondary.dark,
  transition: 'all ease 0.3s',
  textTransform: 'initial',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    transition: 'all ease 0.3s',
  },
}

export const ButtonSecondaryOutlinedStyles = withStyles({
  root: ButtonSecondaryOutlinedProperties,
})(Button)

export const ButtonSecondaryOutlined: React.FC<ButtonProps> = ({
  className,
  children,
  ...props
}): React.ReactElement => {
  return (
    <ButtonSecondaryOutlinedStyles
      data-testid="buttonSecondary"
      variant="outlined"
      className={clsx(className)}
      {...props}
    >
      {children}
    </ButtonSecondaryOutlinedStyles>
  )
}
