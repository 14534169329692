import React, { useState, useEffect } from 'react'
import CarouselComponent from '@flatsy/core/Carousel/'
import clsx from 'clsx'
import { useStyles } from './Styles'
import { ICarouselProperty } from './Type'

const Carousel = ({ property, size, noBorderRadius }: ICarouselProperty): React.ReactElement => {
  const classes = useStyles()
  const [slides, setSlides] = useState(property?.media?.pictures || [])

  useEffect(() => {
    if (property?.media?.pictures && property?.media?.pictures.length) {
      setSlides(property?.media?.pictures)
    }
  }, [property])

  if (slides.length === 0) {
    return <></>
  }

  return (
    <div
      className={clsx(
        classes.container,
        size === 'large' && classes.containerLarge,
        size === 'small' && classes.containerSmall
      )}
    >
      <CarouselComponent id="property-image" slides={slides} noBorderRadius={noBorderRadius} />
    </div>
  )
}

export default Carousel
