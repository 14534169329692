import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from 'i18next-chained-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { DateTime } from 'luxon'

i18n
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    returnNull: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: (value, formatting, lng) => {
        if (value instanceof DateTime) {
          return value.setLocale(lng || 'fr').toFormat(formatting || '')
        }
        return value
      },
    },
    load: 'languageOnly',
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng',
    },
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'fr',
    debug: false,
    ns: ['app'],
    defaultNS: 'app',
    backend: {
      backends: [
        LocalStorageBackend,
        HttpApi,
        resourcesToBackend((language, namespace, callback) => {
          import(`./locales/${language}/${namespace}.${language}.json`)
            .then(({ default: resources }) => {
              callback(null, resources)
            })
            .catch((error) => {
              callback(error, null)
            })
        }),
      ],
      backendOptions: [
        {
          expirationTime: 60 * 1000,
          version: { en: process.env.REACT_APP_VERSION, fr: process.env.REACT_APP_VERSION },
        },
        {
          loadPath: `/locales/{{lng}}/{{ns}}.{{lng}}.json`,
        },
      ],
    },
    react: {
      bindI18n: 'languageChanged loaded',
    },
  })

export default i18n
