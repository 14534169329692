import { Body1 } from '@flatsy/core/MaterialUi/Typo'
import { toNameCase } from '@flatsy/core/MaterialUi/Typo/Utile'
import theme from '@flatsy/core/MuiTheme/theme'
import i18n from '@flatsy/utils/translations'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button } from '@mui/material'
import { LogoAgency } from 'src/utils/LogoAgency'
import useStyles from './Style'
import { TopbarProps } from './Type'

export const Topbar: React.FC<TopbarProps> = ({ toggleDrawer, customer }) => {
  const classes = useStyles()

  return (
    <Button className={classes.topBar} onClick={toggleDrawer(true)}>
      <div className={classes.logoWrapper}>{LogoAgency(classes, customer?.design?.logoUrl)}</div>
      <div className={classes.topBarContent}>
        <Body1 fontColor={theme.palette.secondary.dark}>{toNameCase(i18n.t('show_more'))}</Body1>
        <ExpandMoreIcon className={classes.iconMore} />
      </div>
    </Button>
  )
}
