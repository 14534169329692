import { CUSTOMER_BASE_URL } from '../../constants'
import { Admin } from './Admin'
import { Pro } from './Pro'
import { Public } from './Public'

const configuration = { baseURL: CUSTOMER_BASE_URL }

export const FlatsyPublicCustomerClient = new Public(configuration)
export const FlatsyAdminCustomerClient = new Admin(configuration)
export const FlatsyProCustomerClient = (hybridToken: string): Pro =>
  new Pro({ ...configuration, hybridToken })
