/* eslint-disable @typescript-eslint/ban-ts-comment */
import clsx from 'clsx'
import { ExtenedTypographyProps } from '../Type'
import { useTypoStyles } from '../Utile'
import { Header1Style, Header2Style, Header3Style } from './Styles'

/* --------------------------------- HEAD 1 --------------------------------- */

export const Header1: React.FC<ExtenedTypographyProps> = ({
  className,
  lowerCase,
  fontColor,
  children,
  ...props
}): React.ReactElement => {
  const classes = useTypoStyles({
    lowerCase,
    fontColor,
    ...props,
  })
  return (
    <Header1Style
      // @ts-ignore
      component="h3"
      {...props}
      className={clsx(lowerCase ? classes.Lower : classes.Upper, className, classes.color)}
    >
      {children}
    </Header1Style>
  )
}

/* --------------------------------- HEAD 2 --------------------------------- */

export const Header2: React.FC<ExtenedTypographyProps> = ({
  className,
  lowerCase,
  fontColor,
  children,
  ...props
}): React.ReactElement => {
  const classes = useTypoStyles({
    lowerCase,
    fontColor,
    ...props,
  })
  return (
    <Header2Style
      // @ts-ignore
      component="h3"
      className={clsx(lowerCase ? classes.Lower : classes.Upper, className, classes.color)}
      {...props}
    >
      {children}
    </Header2Style>
  )
}

/* --------------------------------- HEAD 3 --------------------------------- */

export const Header3: React.FC<ExtenedTypographyProps> = ({
  className,
  lowerCase,
  fontColor,
  children,
  ...props
}): React.ReactElement => {
  const classes = useTypoStyles({
    lowerCase,
    fontColor,
    ...props,
  })
  return (
    <Header3Style
      // @ts-ignore
      component="h3"
      className={clsx(lowerCase ? classes.Lower : classes.Upper, className, classes.color)}
      {...props}
    >
      {children}
    </Header3Style>
  )
}
