/* eslint-disable react/destructuring-assignment */
import { Header2 } from '@flatsy/core/MaterialUi/Typo'
import { Button, Dialog, DialogActions, DialogContentText, DialogTitle } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonError } from '../../../MaterialUi/Button'
import { toNameCase } from '../../../MaterialUi/Typo/Utile'
import LayoutDialogContent from '../Content'
import { useStyles } from './Style'
import { MainProps } from './Type'
import { Transition } from './Utils'

const LayoutDialog: React.FC<MainProps> = ({
  open,
  title,
  endTitle,
  children,
  onClose,
  onConfirm,
  confirmLabel,
  closeLabel,
  customButtons,
  addButtons,
  disableConfirm,
  disableClose,
  noAction,
  actionSeverity,
  isMobile,
  unMount,
  bottomComponent,
  backgroundWhite,
  ...props
}) => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  const isKeepMounted = () => {
    if (unMount) return false
    return true
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={isMobile ? Transition : undefined}
      maxWidth="md"
      {...props}
      keepMounted={isKeepMounted()}
      className={clsx(classes.main, props.className && props.className)}
    >
      <div className={classes.row}>
        <DialogTitle className={backgroundWhite ? classes.dialogTitleWhite : classes.dialogTitle}>
          <Header2 lowerCase className={classes.title}>
            {title ? toNameCase(title) : ''}
          </Header2>
        </DialogTitle>
        {endTitle}
      </div>
      <LayoutDialogContent backgroundWhite={backgroundWhite}>
        <DialogContentText component="div">{children}</DialogContentText>
      </LayoutDialogContent>
      {!noAction && (
        <DialogActions
          className={bottomComponent ? classes.dialogActionCustom : classes.dialogAction}
        >
          {customButtons ? (
            customButtons.map((singleButton) => singleButton)
          ) : (
            <>
              {bottomComponent && bottomComponent}
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  onClick={onClose}
                  disableElevation
                  disabled={disableClose}
                >
                  {closeLabel || i18n.t('close')}
                </Button>
                {onConfirm &&
                  (actionSeverity ? (
                    actionSeverity === 'high' && (
                      <ButtonError
                        className={classes.btnConfirm}
                        onClick={onConfirm}
                        disabled={disableConfirm}
                      >
                        {confirmLabel || 'Ok'}
                      </ButtonError>
                    )
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onConfirm}
                      disableElevation
                      disabled={disableConfirm}
                      className={classes.btnConfirm}
                    >
                      {confirmLabel || 'Ok'}
                    </Button>
                  ))}
                {addButtons && addButtons.map((singleButton) => singleButton)}
              </div>
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default LayoutDialog
