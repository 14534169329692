import iconAccessCode from '../static/assets/images/Icons/accessCode.svg'
import iconAccountCircleOff from '../static/assets/images/Icons/account_circle_off.svg'
import atOurOffice from '../static/assets/images/Icons/atOurOffice.svg'
import iconAwesome from '../static/assets/images/Icons/awesome.svg'
import iconBathPublicLarge from '../static/assets/images/Icons/bathPublicLarge.svg'
import iconBlock from '../static/assets/images/Icons/block.svg'
import iconCalendarAddOn from '../static/assets/images/Icons/calendarAddOn.svg'
import iconCheck from '../static/assets/images/Icons/check.svg'
import directionalSign from '../static/assets/images/Icons/directionalSign.svg'
import iconEmailSend from '../static/assets/images/Icons/emailSend.svg'
import iconEventUpcoming from '../static/assets/images/Icons/event-upcoming.svg'
import fastStaff from '../static/assets/images/Icons/fast.svg'
import fastStaffGrey from '../static/assets/images/Icons/fastStaffGrey.svg'
import iconFloors from '../static/assets/images/Icons/floors.svg'
import iconGppMaybe from '../static/assets/images/Icons/gppMaybe.svg'
import handHoldingKey from '../static/assets/images/Icons/handHoldingKey.svg'
import iconHandshake from '../static/assets/images/Icons/handshake.svg'
import hourglass0h from '../static/assets/images/Icons/hourglass0h.svg'
import hourglass12h from '../static/assets/images/Icons/hourglass12h.svg'
import hourglass24h from '../static/assets/images/Icons/hourglass24h.svg'
import hourglass48h from '../static/assets/images/Icons/hourglass48h.svg'
import identityPlatform from '../static/assets/images/Icons/identityPlatform.svg'
import keyBox from '../static/assets/images/Icons/keyBox.svg'
import iconLocationAway from '../static/assets/images/Icons/location_away.svg'
import iconLogout from '../static/assets/images/Icons/logout.svg'
import mapWithPin from '../static/assets/images/Icons/mapWithPin.svg'
import iconNoteWarning from '../static/assets/images/Icons/noteWarning.svg'
import officeBuildingOutline from '../static/assets/images/Icons/officeBuildingOutline.svg'
import openWithKey from '../static/assets/images/Icons/openWithKey.svg'
import openWithSomeone from '../static/assets/images/Icons/openWithSomeone.svg'
import password from '../static/assets/images/Icons/password.svg'
import iconPayments from '../static/assets/images/Icons/payments.svg'
import iconPublish from '../static/assets/images/Icons/publish.svg'
import iconPublishDark from '../static/assets/images/Icons/publishDark.svg'
import iconPublishLight from '../static/assets/images/Icons/publishLight.svg'
import iconRuleGrey from '../static/assets/images/Icons/rule-grey.svg'
import iconRule from '../static/assets/images/Icons/rule.svg'
import iconTaskGreen from '../static/assets/images/Icons/task-green.svg'
import iconTask from '../static/assets/images/Icons/task.svg'
import iconToken from '../static/assets/images/Icons/token.svg'
import iconWaterVocBlue from '../static/assets/images/Icons/water_voc-blue.svg'
import iconWaterVoc from '../static/assets/images/Icons/water_voc.svg'
import addFile from '../static/assets/images/addFile.svg'
import arrowStep2 from '../static/assets/images/arrow2.svg'
import arrowStep3 from '../static/assets/images/arrow3.svg'
import arrowStep4 from '../static/assets/images/arrow4.svg'
import arrowStep1 from '../static/assets/images/arrowStep1.svg'
import calendarParam from '../static/assets/images/calendarParam.png'
import calendarParam2 from '../static/assets/images/calendarParam2.png'
import calendarParam3 from '../static/assets/images/calendarParam3.png'
import collecting from '../static/assets/images/collecting_fjjl.svg'
import crown from '../static/assets/images/crown.png'
import Dossierfacile from '../static/assets/images/dossierfacile.png'
import empty from '../static/assets/images/empty.png'
import empty2 from '../static/assets/images/empty2.svg'
import emptyFiles from '../static/assets/images/emptyFiles.svg'
import emptyGhost from '../static/assets/images/emptyGhost.svg'
import errorOutline from '../static/assets/images/errorOutline.svg'
import fileViewerProcess from '../static/assets/images/file-process.svg'
import FrLabel from '../static/assets/images/frLabel.svg'
import googleAgendaLogo from '../static/assets/images/googleAgendaLogo.png'
import indispo from '../static/assets/images/indispo.svg'
import larmina from '../static/assets/images/larmina.png'
import li2015 from '../static/assets/images/li2015.png'
import lightBulb from '../static/assets/images/light_bulb.png'
import lightBulbNew from '../static/assets/images/lightBulbNew.svg'
import logo from '../static/assets/images/logo.png'
import logo_white from '../static/assets/images/logo_white.svg'
import miniLogo from '../static/assets/images/mini_logo.svg'
import modal from '../static/assets/images/modal.svg'
import noBooking from '../static/assets/images/no-booking.svg'
import no_cv from '../static/assets/images/no-cv-illu.svg'
import noMission from '../static/assets/images/noMission.svg'
import notFound from '../static/assets/images/notfound.svg'
import notFound_color from '../static/assets/images/notfound_color.svg'
import officeLogo from '../static/assets/images/officeLogo.png'
import padlock from '../static/assets/images/padlock.png'
import calendarPattern from '../static/assets/images/pattern.png'
import pic from '../static/assets/images/pic.png'
import pinel from '../static/assets/images/pinel.png'
import pli from '../static/assets/images/pli.png'
import professional_card from '../static/assets/images/professional_card.svg'
import profilIllustration from '../static/assets/images/profil_illustration.svg'
import sample_pdf1 from '../static/assets/images/sample/pdf.jpg'
import sample_pdf2 from '../static/assets/images/sample/pdf2.jpg'
import smallFlatsyLogo from '../static/assets/images/small-flatsy.svg'
import uploadValidated from '../static/assets/images/upload-validated.svg'
import vertulooLogo from '../static/assets/images/vertulooLogo.svg'
import vertulooLogoSmall from '../static/assets/images/vertulooLogoSmall.svg'
import vertulooTextSmall from '../static/assets/images/vertulooTextSmall.svg'
import qualification_ballon1 from '../static/assets/images/visitorForm/ballon1.svg'
import qualification_ballon2 from '../static/assets/images/visitorForm/ballon2.svg'
import qualification_ballon3 from '../static/assets/images/visitorForm/ballon3.svg'
import qualification_success from '../static/assets/images/visitorForm/success.svg'
import qualification_alreadyTaken from '../static/assets/images/visitorForm/watch.svg'
import waiting from '../static/assets/images/waiting.svg'
import noProperty from '../static/assets/images/noProperty.svg'
import collective from '../static/assets/images/collective.svg'
import iconParking from '../static/assets/images/Icons/parking.svg'
import iconStorage from '../static/assets/images/Icons/storage.svg'
import RealEstateAgent from '../static/assets/images/Icons/real-estate-agent.svg'

const image = {
  logo,
  logo_white,
  smallFlatsyLogo,
  vertulooLogo,
  vertulooLogoSmall,
  empty,
  emptyGhost,
  noMission,
  waiting,
  collecting,
  padlock,
  uploadValidated,
  iconAccessCode,
  iconWaterVocBlue,
  iconAccountCircleOff,
  iconBathPublicLarge,
  iconCalendarAddOn,
  calendarParam,
  calendarParam2,
  calendarParam3,
  arrowStep1,
  arrowStep2,
  arrowStep3,
  arrowStep4,
  professional_card,
  addFile,
  pic,
  lightBulb,
  lightBulbNew,
  FrLabel,
  Dossierfacile,
  emptyFiles,
  empty2,
  indispo,
  notFound,
  notFound_color,
  calendarPattern,
  crown,
  pinel,
  li2015,
  pli,
  modal,
  iconCheck,
  iconBlock,
  iconEventUpcoming,
  iconWaterVoc,
  iconAwesome,
  iconNoteWarning,
  iconEmailSend,
  iconFloors,
  iconPayments,
  iconPublish,
  iconPublishLight,
  iconPublishDark,
  iconRule,
  iconGppMaybe,
  iconToken,
  iconLocationAway,
  iconRuleGrey,
  iconTask,
  iconTaskGreen,
  iconLogout,
  fileViewerProcess,
  no_cv,
  larmina,
  miniLogo,
  profilIllustration,
  identityPlatform,
  password,
  errorOutline,
  vertulooTextSmall,
  qualification: {
    alreadyTaken: qualification_alreadyTaken,
    success: qualification_success,
    ballon1: qualification_ballon1,
    ballon2: qualification_ballon2,
    ballon3: qualification_ballon3,
  },
  company: {
    office: officeLogo,
    googleAgenda: googleAgendaLogo,
  },
  sample: {
    pdf: {
      pdf1: sample_pdf1,
      pdf2: sample_pdf2,
    },
  },
  fastStaff,
  fastStaffGrey,
  iconHandshake,
  openWithKey,
  openWithSomeone,
  atOurOffice,
  directionalSign,
  mapWithPin,
  keyBox,
  handHoldingKey,
  hourglass0h,
  hourglass12h,
  hourglass24h,
  hourglass48h,
  officeBuildingOutline,
  noBooking,
  noProperty,
  collective,
  iconParking,
  iconStorage,
  RealEstateAgent,
}

const color = {
  grey01: '#4F5057',
  grey02: '#4F5057',
}

export const FlatsyTheme = {
  image,
  color,
}

export default FlatsyTheme
