import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import theme from '../../MuiTheme/theme'

/* ---------------------- BUTTON ERROR / SEVERITY HIGH ---------------------- */

export const ButtonErrorStyles = withStyles({
  root: {
    textTransform: 'initial',
    backgroundColor: theme.palette.error.main,
    paddingTop: 8,
    paddingBottom: 8,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
})(Button)
