import React from 'react'
import { Button, Dialog } from '@material-ui/core'
import { Clear, ChevronLeft, ChevronRight } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import clsx from 'clsx'
import { useSwipeable } from 'react-swipeable'
import { Body1 } from '../../MaterialUi/Typo'
import { useStyle } from './Style'
import { ModalProps } from './Type'
import { useCarousel } from '../Hooks'
import { MoveFn } from '../Type'

const ModalImage: React.FC<ModalProps> = ({ open, id, setOpen, slides, positions }) => {
  const classes = useStyle()

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
    event?.preventDefault()
    setOpen({
      open: false,
      position: 0,
    })
  }

  const moveSlider = (values: MoveFn) => {
    if (values.moverElement) {
      values.moverElement.style.transform = `translateX(calc(-${values.index} * (${values.imageSize}px))`
    }
  }

  const carousel = useCarousel({
    slides,
    move: moveSlider,
    id: `${id}-fullscreen`,
    numberOfImageInScreen: 1,
    startAt: positions,
  })

  const handleClickPrev = (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
    event?.preventDefault()
    carousel.event.previous()
  }

  const handleClickNext = (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
    event?.preventDefault()
    carousel.event.next()
  }

  const handleSwipe = (direction: 'Left' | 'Right' | 'Up' | 'Down') => {
    if (direction === 'Left') {
      carousel.event.next()
    } else if (direction === 'Right') {
      carousel.event.previous()
    }
  }

  const swipeHandler = useSwipeable({
    onSwiped: (eventData) => handleSwipe(eventData.dir),
  })

  return (
    <Dialog
      className={classes.dialog}
      onClose={handleClick}
      open={open}
      maxWidth="md"
      id="dialog"
      keepMounted
      {...swipeHandler}
    >
      <div className={classes.mover} id={carousel.ids.mover}>
        {slides.map((slide, key) => (
          <div className={clsx(classes.slideModal, carousel.ids.item)} key={key}>
            <img src={slide} alt="asset" className={classes.img} />
          </div>
        ))}
      </div>
      <Button onClick={handleClick} className={classes.cross}>
        <Clear />
      </Button>
      <IconButton
        onClick={handleClickPrev}
        className={carousel.showArrow.prev ? classes.arrowButtonLeft : classes.arrowHidden}
      >
        <ChevronLeft className={classes.arrow} />
      </IconButton>
      <IconButton
        onClick={handleClickNext}
        className={carousel.showArrow.next ? classes.arrowButtonRight : classes.arrowHidden}
      >
        <ChevronRight className={classes.arrow} />
      </IconButton>
      <div className={classes.nbPicture}>
        <PhotoCameraIcon />
        <Body1 className={classes.body1}>
          {carousel.indexShow} sur {carousel.totalOfSlide}
        </Body1>
      </div>
    </Dialog>
  )
}

export default ModalImage
