import { FlatsyPublicCustomerClient } from '@flatsy/api/client/3.0.0/services/Customer'
import { FlatsyBaseCustomer } from '@flatsy/api/client/3.0.0/services/Customer/data-contracts'
import { UseQueryResult, useQuery } from 'react-query'

export const useCustomerById = (
  publicToken: string,
  customerId: string
): UseQueryResult<FlatsyBaseCustomer> => {
  const property = useQuery({
    queryKey: ['customer', customerId],
    queryFn: () => {
      return FlatsyPublicCustomerClient.customerDetail(customerId || '', {
        headers: {
          Authorization: `Bearer ${publicToken}`,
          Accept: '*/*',
        },
      }).then((response) => {
        return response.data
      })
    },
    keepPreviousData: true,
    structuralSharing: true,
    cacheTime: 10 * 60,
    enabled: customerId !== undefined && customerId !== '',
  })
  return property
}
