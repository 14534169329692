/* eslint-disable react-i18n/no-dynamic-translation-keys */
import Separator from '@flatsy/core/Layout/Separator'
import LoaderWrapper from '@flatsy/core/Loader/Wrapper'
import { Body1, Body3, Header1, SubBody1 } from '@flatsy/core/MaterialUi/Typo'
import { toNameCase } from '@flatsy/core/MaterialUi/Typo/Utile'
import FlatsyTheme from '@flatsy/core/MuiTheme/flatsyTheme'
import { renderDuration } from '@flatsy/utils/Functions/Duration'
import { floorFormat, surfaceFormat } from '@flatsy/utils/Functions/format/Conversions'
import i18n from '@flatsy/utils/translations'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined'
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import SquareFootIcon from '@material-ui/icons/SquareFoot'
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import { Box, List, ListItemText, SwipeableDrawer } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import { DateTime } from 'luxon'
import { Fragment, useContext, useState } from 'react'
import FormGdpr from 'src/components/Gdpr'
import { Line } from 'src/components/Line'
import { LineDrawer } from 'src/components/LineDrawer'
import { ApplicationContext } from 'src/context/Application'
import { renderCheckItem, renderFg } from '../../Utils'
import { Topbar } from '../Topbar'
import { renderCarousel } from '../Utils'
import useStyles from './Style'

export const SuccessMobile: React.FC = () => {
  const classes = useStyles()
  const {
    missionQuery,
    customer,
    property,
    booking: { selected },
  } = useContext(ApplicationContext)
  const [state, setState] = useState({
    bottom: false,
  })

  if (
    (missionQuery && missionQuery.isLoading === true) ||
    !missionQuery ||
    !missionQuery.data ||
    missionQuery.data === undefined ||
    !selected
  ) {
    return <LoaderWrapper />
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setState({ ...state, bottom: open })
  }

  const renderItem = (icon: React.ReactElement, label: string, text: string) => {
    return (
      <>
        <div className={classes.flexContainer}>
          <div className={classes.flexFurnished}>
            {icon}
            <SubBody1 className={classes.label}>{i18n.t(`${label}`)}</SubBody1>
          </div>
          <SubBody1 className={classes.description}>{i18n.t(`${text}`)}</SubBody1>
        </div>
        <Separator size="8px" />
      </>
    )
  }

  const drawerInformation = () => (
    <Box role="presentation" height="780px">
      <LineDrawer />
      <List disablePadding>
        <ListItem key={1} disablePadding>
          {renderCarousel(property)}
        </ListItem>
        <Separator size="24px" />
        <ListItem key={2} disablePadding>
          {property && property.publication?.showAddress && (
            <ListItemText
              primary={
                <div className={classes.containerInfoDrawer}>
                  {property.estateType &&
                    renderItem(
                      <HomeWorkOutlinedIcon className={classes.icon} />,
                      'type',
                      property.estateType?.toLocaleLowerCase()
                    )}
                  <div className={classes.flexContainer}>
                    <div className={classes.flexFurnished}>
                      <ChairOutlinedIcon className={classes.icon} />
                      <SubBody1 className={classes.label}>{i18n.t('furnished')}</SubBody1>
                    </div>
                    {renderCheckItem(property.features?.isFurnished)}
                  </div>
                  <Separator size="8px" />
                  {(property.spaces?.residential?.livingSpace || 0) > 0 &&
                    renderItem(
                      <SquareFootIcon className={classes.icon} />,
                      'surface',
                      surfaceFormat(property.spaces?.residential?.livingSpace || 0)
                    )}
                  {(property.structure?.rooms?.numberOfRooms || 0) > 0 &&
                    renderItem(
                      <LayersOutlinedIcon className={classes.icon} />,
                      'floor',
                      floorFormat(property.location?.floorNumber || 0, true)
                    )}
                  {(property.structure?.rooms?.numberOfRooms || 0) > 0 &&
                    renderItem(
                      <DashboardOutlinedIcon className={classes.icon} />,
                      property.structure &&
                        property.structure.rooms &&
                        property.structure.rooms.numberOfRooms &&
                        property.structure.rooms.numberOfRooms > 1
                        ? 'rooms'
                        : 'room',
                      (property?.structure?.rooms?.numberOfRooms || 0).toString()
                    )}
                  {(property.structure?.rooms?.numberOfBedrooms || 0) > 0 &&
                    renderItem(
                      <HotelOutlinedIcon className={classes.icon} />,
                      property.structure &&
                        property.structure.rooms &&
                        property.structure.rooms.numberOfBedrooms &&
                        property.structure.rooms.numberOfBedrooms > 1
                        ? 'bedrooms'
                        : 'bedroms',
                      (property?.structure?.rooms?.numberOfBedrooms || 0).toString()
                    )}
                  <Separator size="8px" />
                  <Line />
                  <Separator size="16px" />
                  <div className={classes.flex}>
                    <PlaceOutlinedIcon className={classes.icon} />
                    <Body1
                      className={classes.description}
                    >{`${property.location?.streetAddress}, ${property.location?.locality}`}</Body1>
                  </div>
                </div>
              }
            />
          )}
        </ListItem>
      </List>
    </Box>
  )

  return (
    <div className={classes.fullContainer}>
      <Fragment key="bottom">
        <Topbar toggleDrawer={toggleDrawer} customer={customer} />
        <Separator size="16px" />
        <div className={classes.fullContainerWhite}>
          <div className={classes.image}>
            <img alt="success" src={FlatsyTheme.image.qualification.success} />
          </div>
          <Header1 lowerCase className={classes.title}>
            {i18n.t('confirmation_of_your_appointment')}
          </Header1>
          <Separator size="64px" />
          <div className={classes.row}>
            <Body1>{i18n.t('date_and_time')} </Body1>
            <Body3 style={{ textAlign: 'right' }}>
              {selected && toNameCase(i18n.t('@date_at', { value: DateTime.fromISO(selected) }))}
            </Body3>
          </div>
          <Line />
          <Separator size="16px" />
          {property?.location?.streetAddress && (
            <div className={classes.row}>
              <Body1>{i18n.t('duration')}</Body1>
              <Body3>
                {missionQuery && missionQuery.data && renderDuration(missionQuery.data)}
              </Body3>
            </div>
          )}
          <Line />
          <Separator size="16px" />
          <div className={classes.row}>
            <Body1>{i18n.t('your_agent')}</Body1>
            <Body3>{renderFg(missionQuery)}</Body3>
          </div>
          <FormGdpr customer={customer} />
        </div>
        <SwipeableDrawer
          anchor="bottom"
          open={state.bottom}
          onOpen={toggleDrawer(true)}
          onClose={toggleDrawer(false)}
          className={classes.swipeable}
        >
          {drawerInformation()}
        </SwipeableDrawer>
      </Fragment>
    </div>
  )
}
