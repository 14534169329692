import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100%!important',
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.common.white,
      paddingBottom: 58,
      width: '100%',
      padding: 0,
      margin: 0,
    },
  },
}))
