import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => {
  return {
    header: {
      backgroundColor: '#FFF',
      padding: '11.75px 32px',
      width: '100%',
      minHeight: 'auto',
      boxShadow: '0px 1px 3px rgba(0,0,0,0.12), 0px 0px 0px 1px rgba(19,45,74,0.04)',
      justifyContent: 'space-between',
    },
    left: {
      display: 'flex',
    },
    nav: {
      display: 'flex',
      flexGrow: 1,
      marginLeft: 24,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginLeft: 0,
      },
    },
    navLink: {
      color: theme.palette.secondary.dark,
      padding: '9px 11px',
      margin: '0 7.5px',
      borderRadius: '4px',
      fontSize: '14px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: 'auto',
        fontSize: '16px',
        lineHeight: '16px',
        borderRadius: '100px',
        padding: '9px 11px',
        '&.booking': {
          width: '110.84px',
          [theme.breakpoints.down('sm')]: {
            width: 'auto',
          },
        },
        '&.dossier': {
          width: '96px',
          [theme.breakpoints.down('sm')]: {
            width: 'auto',
          },
        },
      },
    },
    cancelButton: {
      padding: 0,
    },
    navigationIcon: {
      marginRight: 8,
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'inline-block',
      },
    },
    navigationLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'inline-block',
      },
    },
    navLinkActive: {
      color: theme.palette.primary.main,
      background: theme.palette.primary.light,
      '& $navigationLabel': {
        display: 'inline-block',
      },
      '&.booking': {
        marginLeft: '0',
        width: 'auto',
      },
      '&.dossier': {
        marginRight: '0',
        width: 'auto',
      },
    },
    logo: {
      width: '100%',
      height: 40,
      objectFit: 'contain',
    },
    logoContainer: {
      maxWidth: 200,
      height: '40px',
    },
    bottomNavigation: {
      background: 'white',
      width: '100%',
      top: 'initial',
      bottom: 0,
      height: 'auto',
      padding: '8px 24px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: '0px -1px 1px rgba(0,0,0,0.13)',
      zIndex: 9,
    },
  }
})
