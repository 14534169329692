import theme from '@flatsy/core/MuiTheme/theme'
import { Snackbar, ThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import * as ROUTES from 'src/constants/Routes'
import NotFound from 'src/containers/NotFound'
import { useMobileRedux } from 'src/hooks/Mobile'
import ServerError from '../ServerError'
import AppAlert from './Alert'
import ApplicationRouter from './ApplicationRouter'
import { useStyle } from './Styles'

const App: React.FC = () => {
  const classes = useStyle()
  const queryClient = new QueryClient()
  useMobileRedux()

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router basename={process.env.PUBLIC_URL}>
            <Snackbar
              open
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              className={clsx(classes.alert, 'globalError__snackbar')}
            >
              <Alert severity="error" variant="filled" className="globalError__alert" />
            </Snackbar>
            <AppAlert />
            <Switch>
              <Route path={ROUTES.APPLICATION} component={ApplicationRouter} />
              <Route path={ROUTES.NOT_FOUND} component={NotFound} />
              <Route path={ROUTES.SERVER_ERROR} component={ServerError} />
              <Redirect to={{ pathname: ROUTES.NOT_FOUND }} />
            </Switch>
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
