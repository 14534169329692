import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => {
  return {
    container: {
      padding: '36px 40px',
      position: 'relative',
      boxShadow: '0px 0px 0px 1px rgb(19 45 74 / 4%)',
      borderRadius: 6,
    },
    containerNoPadding: {
      padding: '36px 0',
      position: 'relative',
      boxShadow: '0px 0px 0px 1px rgb(19 45 74 / 4%)',
      borderRadius: 6,
    },
    inner: {
      padding: '0 40px',
    },
  }
})
