import { format as _format, formatISO9075, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'

export function format(date: Date | number, formatStr = 'PP'): string {
  return _format(date, formatStr, {
    locale: fr,
  })
}

export const formatSlotForBackEnd = (
  start: Date,
  end: Date
): { start: string; end: string; date: string } => {
  const finalObj = {
    start: format(start, 'HH:mm:ss'),
    end: format(end, 'HH:mm:ss'),
    date: format(start, 'yyyy-MM-dd'),
  }

  return finalObj
}

export const formatSlotForFrontEnd = (
  start: string,
  end: string,
  date: string,
  dateEnd?: string
): {
  time: {
    start: string
    end: string
  }
  iso: {
    start: string
    end: string
  }
  date: {
    start: Date
    end: Date
  }
} => {
  const startDate = parseISO(`${date}T${start}`)
  const endDate = parseISO(`${dateEnd || date}T${end}`)
  const finalObj = {
    time: {
      start: formatISO9075(startDate, {
        representation: 'time',
      }),
      end: formatISO9075(endDate, {
        representation: 'time',
      }),
    },
    iso: {
      start: formatISO9075(startDate),
      end: formatISO9075(endDate),
    },
    date: {
      start: startDate,
      end: endDate,
    },
  }

  return finalObj
}

export const resetHoursMinutes = (date: Date): Date => {
  const theDate = date

  theDate.setHours(0)
  theDate.setMinutes(0)
  theDate.setSeconds(0)
  theDate.setMilliseconds(0)

  return theDate
}
