import React from 'react'
import { DialogContent } from '@material-ui/core'
import { useStyleConfirm } from './Style'
import { LayoutDialogProps } from './Type'

const LayoutDialogContent: React.FC<LayoutDialogProps> = ({
  backgroundWhite,
  ...props
}): React.ReactElement => {
  const classes = useStyleConfirm()

  return (
    <DialogContent
      className={backgroundWhite ? classes.confirmContentWhite : classes.confirmContent}
      {...props}
    />
  )
}

export default LayoutDialogContent
