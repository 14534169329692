import { DateTime } from 'luxon'

export const parseApiDate = (date: Date | string): Date => {
  if (!date) {
    return new Date()
  }

  if (typeof date !== 'string') {
    return DateTime.fromISO(date.toString()).toJSDate()
  }

  return DateTime.fromISO(date).toJSDate()
}

export const isValidDate = (date: Date | null): boolean => {
  if (date && Object.prototype.toString.call(date) === '[object Date]') {
    if (Number.isNaN(date.getTime())) {
      return false
    }
    return true
  }

  return false
}
