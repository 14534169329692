import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import theme from '../../../MuiTheme/theme'

export const NavBarHeaderStyle = withStyles({
  root: {
    fontWeight: 300,
    fontSize: 22,
    lineHeight: '26px',
    letterSpacing: '-0.01em',
    color: theme.palette.common.black,
  },
})(Typography)
