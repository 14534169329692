import theme from '@flatsy/core/MuiTheme/theme'
import { makeStyles, Theme } from '@material-ui/core'

const useStyle = makeStyles<Theme, { numberDays: number; screenHeight: number }>({
  header: {
    padding: theme.spacing(1),
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  day: {
    display: 'flex',
    flexDirection: 'column',
    width: (props) => `calc(100%/${props.numberDays})`,
    marginRight: theme.spacing(1),
    fontSize: '1.1em',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'calc(45%)',
    },
  },
  dateGrey: {
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: '0.83px',
    lineHeight: '14.32px',
    color: theme.palette.secondary.dark,
  },
  dateDark: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '21.48px',
  },
  slots: {
    flex: 1,
    maxHeight: (props) => props.screenHeight,
    display: 'flex',
    marginBottom: 16,
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'hidden',
    '&:hover': {
      overflowY: 'scroll',
    },
  },
  slot: {
    padding: '6px 24px',
    background: theme.palette.primary.contrastText,
    marginBottom: 8,
    width: '100%',
    margin: 0,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    border: `1px solid ${theme.palette.primary.contrastText}`,
    borderRadius: 6,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(19, 45, 74, 0.04)',
    '& .MuiTypography-root.MuiTypography-body1': {
      fontWeight: 500,
      color: theme.palette.common.black,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      transition: 'all ease 0.3s',
      '& .MuiTypography-root.MuiTypography-body1': {
        color: theme.palette.primary.main,
        transition: 'all ease 0.3s',
      },
    },
    '&:first-child': {
      marginTop: 1,
    },
  },
  selected: {
    background: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
  },
  noOptions: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  line: {
    width: '100%',
    height: 1,
    background: theme.palette.secondary.light,
    marginBottom: 16,
  },
  lineCalendar: {
    width: 16,
    marginTop: 19,
    marginBottom: 19,
    borderTop: `3px solid ${theme.palette.secondary.light}`,
  },
  badge: {
    width: '80%',
  },
})

export default useStyle
