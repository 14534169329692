import React from 'react'
import { SeparatorProps } from './Type'
import { useStyle } from './Style'

const Separator: React.FC<SeparatorProps> = ({ size }): React.ReactElement => {
  const classes = useStyle({ size })
  return <div className={classes.separator} data-testid="separator" />
}

export default Separator
