import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    gap: 8,
  },
  icon: {
    color: theme.palette.secondary.dark,
  },
  description: {
    color: theme.palette.common.black,
    fontWeight: 400,
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    paddingRight: 4,
  },
  image: {
    objectFit: 'contain',
    maxHeight: 88,
  },
  containerLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    margin: 8,
  },
  sidebar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  greyColor: {
    color: theme.palette.secondary.dark,
  },
  blueDarkColor: {
    color: theme.palette.primary.dark,
  },
  dateDuration: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loadingButton: {
    flex: 1,
  },
  label: {
    marginLeft: 8,
    color: theme.palette.secondary.dark,
    fontWeight: 400,
  },
  flexFurnished: {
    display: 'flex',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export default useStyles
