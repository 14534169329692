/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FlatsyPostBooking,
  FlatsyPublicBookable,
  FlatsyPublicBooking,
  FlatsyPublicMissionCommercialViewing,
  FlatsyPublicMissionInspection,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Public<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Retrieve a list of bookables (available slots) for a property for a visitor/applicant/tenant to choose from
   *
   * @tags Bookable
   * @name BookableList
   * @summary (Public) Retrieve a list of bookables for a property
   * @request GET:/public/bookable/
   * @secure
   */
  bookableList = (
    query: {
      /** @format uuid */
      mission_id?: string
      /** @format date */
      starts_at: string
      /** @format date */
      ends_at: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      {
        /**
         * The total of items found
         * @example 800
         */
        count?: number
        items?: FlatsyPublicBookable[]
      },
      any
    >({
      path: `/public/bookable/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    })
  /**
   * @description Request Booking creation
   *
   * @tags Booking
   * @name BookingCreate
   * @summary (Public) Create Booking
   * @request POST:/public/booking
   * @secure
   */
  bookingCreate = (data: FlatsyPostBooking, params: RequestParams = {}) =>
    this.request<FlatsyPublicBooking, any>({
      path: `/public/booking`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description (Public) Get a <<glossary:Booking>> by ID
   *
   * @tags Booking
   * @name BookingDetail
   * @summary (Public) Get a Booking by ID
   * @request GET:/public/booking/{id}
   * @secure
   */
  bookingDetail = (id: string, params: RequestParams = {}) =>
    this.request<FlatsyPublicBooking, void>({
      path: `/public/booking/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    })
  /**
   * @description Retrieve a <<glossary:Mission>> by a Token to show to an Attendee
   *
   * @tags Mission
   * @name MissionForTokenList
   * @summary (Public) Retrieve a Mission for a Token
   * @request GET:/public/mission/for_token
   * @secure
   */
  missionForTokenList = (params: RequestParams = {}) =>
    this.request<FlatsyPublicMissionInspection | FlatsyPublicMissionCommercialViewing, void>({
      path: `/public/mission/for_token`,
      method: 'GET',
      secure: true,
      ...params,
    })
}
