/* eslint-disable react/destructuring-assignment */
import Logo from '@flatsy/core/static/assets/images/logoRegular.svg'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import React from 'react'

export const LogoAgency = (
  classes: ClassNameMap<'logo' | 'logoContainer'>,
  agencyLogo: string | undefined | null
): React.ReactNode => {
  const renderImage = () => {
    if (agencyLogo) {
      return <img src={`${agencyLogo}`} alt="Agency logo" className={classes.logo} />
    }
    return <img src={Logo} alt="Flatsy logo" className={classes.logo} />
  }

  return <div className={classes.logoContainer}>{renderImage()}</div>
}
