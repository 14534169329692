/* eslint-disable no-nested-ternary */
import Separator from '@flatsy/core/Layout/Separator'
import LoaderWrapper from '@flatsy/core/Loader/Wrapper'
import { Body1, Body3, Header1 } from '@flatsy/core/MaterialUi/Typo'
import { toNameCase } from '@flatsy/core/MaterialUi/Typo/Utile'
import FlatsyTheme from '@flatsy/core/MuiTheme/flatsyTheme'
import { renderDuration } from '@flatsy/utils/Functions/Duration'
import { Card, Grid } from '@material-ui/core'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormGdpr from 'src/components/Gdpr'
import { Line } from 'src/components/Line'
import { ApplicationContext } from 'src/context/Application'
import { useCustomerById } from 'src/hooks/Customer'
import { renderFg } from '../../Utils'
import Sidebar from './Sidebar'
import useStyle from './Style'

const Success: React.FC = (): React.ReactElement => {
  const classes = useStyle()
  const { i18n } = useTranslation()
  const [active, setActive] = useState(false)
  const { property, missionQuery } = useContext(ApplicationContext)
  const {
    booking: { selected },
    token,
  } = useContext(ApplicationContext)
  const { data: customer } = useCustomerById(token, missionQuery?.data?.customerId || '')

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 60)
  }, [])

  if (!selected) {
    return <LoaderWrapper />
  }

  return (
    <Grid container style={{ flex: 1 }}>
      <Grid item xs={9} className={classes.containerGrid}>
        <Card variant="outlined" className={classes.containerCardLeft}>
          <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
            <Grid item xs={12} direction="row">
              <div className={classes.container}>
                <div className={classes.wrapper}>
                  <img
                    alt="ballon"
                    src={FlatsyTheme.image.qualification.ballon2}
                    className={clsx(classes.ballon1, active && classes.ballonActive)}
                  />
                  <img
                    alt="ballon"
                    src={FlatsyTheme.image.qualification.ballon1}
                    className={clsx(classes.ballon2, active && classes.ballonActive)}
                  />
                  <img
                    alt="ballon"
                    src={FlatsyTheme.image.qualification.ballon3}
                    className={clsx(classes.ballon3, active && classes.ballonActive)}
                  />
                  <div className={clsx(classes.inner, active && classes.innerActive)}>
                    <img
                      alt="success"
                      src={FlatsyTheme.image.qualification.success}
                      className={clsx(classes.successImage, active && classes.successActive)}
                    />
                    <Header1 align="center" lowerCase>
                      {i18n.t('confirmation_of_your_appointment')}
                    </Header1>
                    <Separator size="24px" />
                    <div className={classes.row}>
                      <Body1>{i18n.t('date_and_time')} </Body1>
                      <Body3>
                        {selected &&
                          toNameCase(i18n.t('@date_at', { value: DateTime.fromISO(selected) }))}
                      </Body3>
                    </div>
                    <Line />
                    <Separator size="16px" />
                    {property?.location?.streetAddress && (
                      <div className={classes.row}>
                        <Body1>{i18n.t('duration')}</Body1>
                        <Body3>
                          {missionQuery && missionQuery.data && renderDuration(missionQuery.data)}
                        </Body3>
                      </div>
                    )}
                    <Line />
                    <Separator size="16px" />
                    <div className={classes.row}>
                      <Body1>{i18n.t('your_agent')}</Body1>
                      <Body3>{renderFg(missionQuery)}</Body3>
                    </div>
                    {/* <Line />
                    <Separator size="16px" />
                    <div className={classes.row}>
                      <Body1>{i18n.t('attendees')}</Body1>
                      <Body3>aaaa</Body3>
                    </div> */}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <FormGdpr customer={customer} />
        </Card>
      </Grid>
      <Grid item xs={3} className={classes.containerGrid}>
        {missionQuery && missionQuery.data && <Sidebar mission={missionQuery.data} />}
      </Grid>
    </Grid>
  )
}

export default Success
