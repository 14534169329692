import {
  FlatsyProMissionCommercialViewing,
  FlatsyProMissionInspection,
} from '@flatsy/api/client/3.0.0/services/Mission/data-contracts'
import theme from '@flatsy/core/MuiTheme/theme'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { UseQueryResult } from 'react-query'
import { AvatarFg } from 'src/components/Avatar'

export const renderFg = (
  missionQuery:
    | UseQueryResult<FlatsyProMissionInspection | FlatsyProMissionCommercialViewing | undefined>
    | undefined
): React.ReactNode => {
  if (
    missionQuery &&
    missionQuery.data &&
    missionQuery.data.staffing &&
    missionQuery.data.staffing.assignments &&
    missionQuery.data.staffing.assignments.length > 0 &&
    missionQuery.data.staffing.assignments[0].agent &&
    missionQuery.data.staffing.assignments[0].agent.id
  ) {
    return <AvatarFg id={missionQuery.data.staffing.assignments[0].agent.id} />
  }
}

export const renderCheckItem = (value: boolean | undefined): React.ReactNode => {
  if (value === false) {
    return <CloseIcon style={{ color: theme.palette.error.main }} />
  }
  return <CheckIcon style={{ color: theme.palette.success.main }} />
}
