import { format } from '@flatsy/utils/Functions/DateTime/Datefns'
import { DateTime } from '@flatsy/utils/dates'
import { Box } from '@material-ui/core'
import { Grid } from '@mui/material'
import { add } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../Hooks'
import useStyle from './Styles'
import { DayPropsEmpty } from './Type'

const DaysEmpty: React.FC<DayPropsEmpty> = ({
  slotsByDay,
  index,
  startDate,
}): React.ReactElement | null => {
  const { numberDays, screenXs } = useUtils()
  const classes = useStyle({ numberDays })
  const { i18n } = useTranslation()

  let slotDay = add(startDate || new Date(), { days: index })
  let slotKeys = format(slotDay, 'P')

  if (screenXs) {
    if (slotsByDay[Object.keys(slotsByDay)[index]]) {
      slotKeys = Object.keys(slotsByDay)[index]
      slotDay = new Date(slotsByDay[slotKeys][0])
    } else {
      return null
    }
  }

  return (
    <Grid container direction="row">
      <Grid item xs={12} className={classes.day} justifyContent="center">
        <Box className={classes.header}>
          <span className={classes.dateGrey}>
            {i18n.t('@date_day', { value: DateTime.fromJSDate(slotDay) })}
          </span>
          <span className={classes.dateDark}>{DateTime.fromJSDate(slotDay).day}</span>
          <span className={classes.dateGrey}>
            {i18n.t('@date_month', { value: DateTime.fromJSDate(slotDay) })}
          </span>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.line} />
      </Grid>
    </Grid>
  )
}

export default DaysEmpty
