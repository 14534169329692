import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  containerCards: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
  },
  containerCardLarge: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 16,
  },
  helper: {
    marginTop: 16,
  },
  helperAgentName: {
    textTransform: 'capitalize',
  },
  sidebar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  containerGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  gridNoBooking: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 16,
  },
  containerCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    margin: 8,
  },
  containerButton: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 16px',
  },
  image: {
    width: 432,
    height: 252,
  },
  noTimeSlot: {
    textAlign: 'center',
  },
}))
