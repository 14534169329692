import theme from '@flatsy/core/MuiTheme/theme'
import { makeStyles, Theme } from '@material-ui/core'

const useStyle = makeStyles<Theme, { numberDays: number }>({
  header: {
    padding: theme.spacing(1),
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  day: {
    display: 'flex',
    flexDirection: 'column',
    width: (props) => `calc(100%/${props.numberDays})`,
    marginRight: theme.spacing(1),
    fontSize: '1.1em',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'calc(45%)',
    },
  },
  dateDark: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '21.48px',
  },
  dateGrey: {
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: '0.83px',
    lineHeight: '14.32px',
    color: theme.palette.secondary.dark,
  },
  line: {
    width: '100%',
    height: 1,
    background: theme.palette.secondary.light,
    marginBottom: 16,
  },
})

export default useStyle
