import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  lineGrey: {
    margin: '16px 0',
    width: 32,
    height: 4,
    background: theme.palette.secondary.main,
    borderRadius: 100,
  },
  containerLine: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default useStyles
