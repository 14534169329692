/* eslint-disable import/no-cycle */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './Type'

export const initialState: AppState = {
  mobile: false,
}

export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    mobile: (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload
    },
  },
})
