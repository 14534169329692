import { LayoutDialog } from '@flatsy/core'
import { Body2 } from '@flatsy/core/MaterialUi/Typo'
import { DialogContentText, Link } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './Style'
import { GdprProps } from './Type'

const FormGdpr: React.FC<GdprProps> = ({ customer }) => {
  const [ShowGDPR, setShowGDPR] = useState(false)
  const { i18n } = useTranslation()
  const classes = useStyles()
  const year = new Date().getFullYear()

  const handleCloseGDPR = () => {
    setShowGDPR(false)
  }

  const handleOpenGDPR = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault()
    setShowGDPR(true)
  }

  return (
    <>
      <Body2 color="textSecondary" variant="body2" className={classes.flatsyGdprButton}>
        {i18n.t('copyright', { year })}{' '}
        <Link
          href="gdpr"
          color="textSecondary"
          variant="body2"
          className={classes.flatsyGdprLink}
          onClick={handleOpenGDPR}
        >
          {i18n.t('personal_data_collection').toLowerCase()}
          {i18n.t('@d')}
        </Link>
      </Body2>
      <LayoutDialog
        onClose={handleCloseGDPR}
        open={ShowGDPR}
        title={i18n.t('personal_data_collection')}
        maxWidth="sm"
      >
        <div>
          <DialogContentText>
            {i18n.t(
              'the_personal_data_collected_in_this_form_and_processed_on_behalf_of_agency_name_are_necessary_for_the_purposes',
              { agency_name: customer?.name }
            )}
          </DialogContentText>
          <DialogContentText>
            {i18n.t(
              'the_personal_data_collected_in_this_form_based_on_the_consent_of_the_person_concerned_is_kept_for_a_period_of_1_year'
            )}
          </DialogContentText>
          <DialogContentText>
            {i18n.t(
              'the_user_has_the_right_to_request_access_rectification_deletion_limitation_or_opposition_to_processing_portability_of_his_or_her_data'
            )}
          </DialogContentText>
        </div>
      </LayoutDialog>
    </>
  )
}

export default FormGdpr
