import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  mover: {
    display: 'flex',
    gridAutoFlow: 'column',
    justifyContent: 'start',
    transition: 'all ease 0.5s',
    height: '100%',
    '& .MuiButton-text': {
      padding: 0,
    },
  },
  slideModal: {
    display: 'flex',
    background: 'black',
    minWidth: '100%',
    '& button': {
      width: '100%',
    },
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    background: 'black',
  },
  arrowButtonLeft: {
    position: 'absolute',
    top: '50%',
    left: 8,
    transform: 'translate(0%, -50%)',
    borderRadius: 16,
    opacity: 0.8,
    background: 'rgba(0, 0, 0, 0.3)',
    transition: 'all ease 0.3s',
    '&:hover': {
      background: theme.palette.primary.light,
      transition: 'all ease 0.3s',
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        transition: 'all ease 0.3s',
      },
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  arrowButtonRight: {
    position: 'absolute',
    top: '50%',
    right: 8,
    transform: 'translate(0%, -50%)',
    borderRadius: 16,
    opacity: 0.8,
    background: 'rgba(0, 0, 0, 0.3)',
    transition: 'all ease 0.3s',
    '&:hover': {
      background: theme.palette.primary.light,
      transition: 'all ease 0.3s',
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        transition: 'all ease 0.3s',
      },
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  arrow: {
    opacity: 1,
    fontSize: 24,
    color: 'white',
    transition: 'all ease 0.3s',
  },
  arrowButton: {
    opacity: 0,
    transition: 'all ease 0.3s',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 8,
    '&:hover': {
      '& $arrowButton': {
        opacity: 1,
        transition: 'all ease 0.3s',
      },
    },
  },
  containerNoRadius: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      '& $arrowButton': {
        opacity: 1,
        transition: 'all ease 0.3s',
      },
    },
  },
  arrowHidden: {
    opacity: '0 !important',
    fontSize: 42,
    padding: 0,
    cursor: 'default',
  },
  nbPicture: {
    position: 'absolute',
    bottom: 8,
    left: 8,
    padding: '5px 10px',
    color: theme.palette.primary.light,
    background: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
  },
  body1: {
    marginLeft: 10,
    color: 'white',
  },
}))
