import theme from '@flatsy/core/MuiTheme/theme'
import { useMediaQuery } from '@material-ui/core'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'src/redux'
import { AppSlice } from 'src/redux/slices/App'

export const useMobileRedux = (): boolean => {
  const dispatch = useAppDispatch()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (matches) {
      dispatch(AppSlice.actions.mobile(true))
    } else if (!matches) {
      dispatch(AppSlice.actions.mobile(false))
    }
  }, [matches])

  return matches
}

export const useMobile = (): boolean => {
  return useSelector((state: RootState) => state.app.mobile)
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const usePrevious = <T extends {}>(value: T): T | undefined => {
  const ref = useRef<T | undefined>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
