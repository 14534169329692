import { buildApiDomainUrl, buildDomainUrl } from '../utils/domain'

export const APPLICATION_BASE_URL = buildDomainUrl(process.env.REACT_APP_FLATSY_API_APPLICATION)
export const CUSTOMER_BASE_URL = buildDomainUrl(process.env.REACT_APP_FLATSY_API_CUSTOMER)
export const BILLING_BASE_URL = buildDomainUrl(process.env.REACT_APP_FLATSY_API_BILLING)
export const BILLING_BASE_URL_V3 = buildApiDomainUrl(process.env.REACT_APP_FLATSY_API_BILLING_V3)
export const PROPERTY_BASE_URL = buildDomainUrl(process.env.REACT_APP_FLATSY_API_PROPERTY)
export const FLATGUIDE_BASE_URL = buildDomainUrl(process.env.REACT_APP_FLATSY_API_FLATGUIDE)
export const BOOKING_BASE_URL = buildDomainUrl(process.env.REACT_APP_FLATSY_API_BOOKING)
export const USER_BASE_URL = buildDomainUrl(process.env.REACT_APP_FLATSY_API_USER)
export const SSO_BASE_URL = buildDomainUrl(process.env.REACT_APP_FLATSY_API_SSO)
export const FLATSY_AGENCY_V1 = buildDomainUrl(process.env.REACT_APP_FLATSY_AGENCY_V1 || '')
