import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => {
  return {
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'white',
      zIndex: 99999999,
      opacity: '0.8',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fixed: {
      position: 'fixed',
    },
    fullWidth: {
      width: '100vw',
      height: '100vh',
    },
    inner: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100vh',
      zIndex: 99,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    absolute: {
      position: 'absolute',
    },
  }
})
