import React from 'react'
import { Skeleton } from '@material-ui/lab'

const LoadingsSlot: React.FC = () => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Skeleton variant="text" width="70%" height="32px" />
      <div style={{ height: '100%', width: '100%', marginTop: 20 }}>
        <Skeleton variant="rect" width="100%" height="59px" />
      </div>
      <div
        style={{
          height: '450px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ height: 450, width: '12%' }}>
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
        </div>
        <div style={{ height: 450, width: '12%' }}>
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
        </div>
        <div style={{ height: 450, width: '12%' }}>
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
        </div>
        <div style={{ height: 450, width: '12%' }}>
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
        </div>
        <div style={{ height: 450, width: '12%' }}>
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
        </div>
        <div style={{ height: 450, width: '12%' }}>
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
        </div>
        <div style={{ height: 450, width: '12%' }}>
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
          <Skeleton variant="rect" width="100%" height="36px" style={{ marginTop: 8 }} />
        </div>
      </div>
    </div>
  )
}

export default LoadingsSlot
