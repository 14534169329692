/* eslint-disable react-i18n/no-dynamic-translation-keys */
import { FlatsyMissionType } from '@flatsy/api/client/3.0.0/services/Mission/data-contracts'
import Separator from '@flatsy/core/Layout/Separator'
import LoaderSmall from '@flatsy/core/Loader/Small'
import { Body1 } from '@flatsy/core/MaterialUi/Typo'
import { toNameCase } from '@flatsy/core/MaterialUi/Typo/Utile'
import { renderDuration } from '@flatsy/utils/Functions/Duration'
import { DateTime } from '@flatsy/utils/dates'
import { Button, Card } from '@material-ui/core'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import EventIcon from '@mui/icons-material/Event'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import { Divider } from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import Carousel from 'src/components/Carousel'
import * as ROUTES from 'src/constants/Routes'
import { ApplicationContext } from 'src/context/Application'
import { useCustomerById } from 'src/hooks/Customer'
import { usePropertyById } from 'src/hooks/Property'
import { useAppDispatch } from 'src/redux'
import { AlertSlice } from 'src/redux/slices/Alert'
import useStyles from './Style'
import { SidebarProps } from './Type'

const Sidebar: React.FC<SidebarProps> = ({ mission }) => {
  const {
    token,
    booking: { selected, setSelected, createBooking },
  } = useContext(ApplicationContext)
  const { data: property } = usePropertyById(token, mission.propertyId || '')
  const { data: customer } = useCustomerById(token, mission.customerId)
  const classes = useStyles()
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const [isBooking, setIsBooking] = useState<boolean>()
  const history = useHistory()

  const renderLogo = () => {
    if (customer && customer.design && customer.design.logoUrl) {
      return (
        <div className={classes.containerLogo}>
          <img src={customer.design.logoUrl} alt="Logo customer" className={classes.image} />
        </div>
      )
    }
  }

  const renderCarousel = () => {
    if (
      property &&
      property.media &&
      property.media.pictures &&
      property.media.pictures?.length !== 0
    ) {
      return (
        <>
          <Separator size="24px" />
          <Carousel size="large" property={property} />
        </>
      )
    }
  }

  const handleSubmitBooking = () => {
    if (!selected) return
    if (createBooking && mission && mission.type === FlatsyMissionType.INSPECTION) {
      setIsBooking(true)
      createBooking(selected)
        .then(() => {
          setSelected(selected)
          setIsBooking(false)
          history.push(
            generatePath(ROUTES.APPLICATION_SUCCESS, {
              public_token: token,
            })
          )
        })
        .catch((err) => {
          dispatch(
            AlertSlice.actions.setAlert({
              content: toNameCase(i18n.t(`${err.errors[0].toLocaleLowerCase()}`)),
              icon: <CancelIcon />,
              open: true,
              severity: 'error',
              timing: 3000,
            })
          )
          setIsBooking(false)
        })
    }
  }

  return (
    <Card variant="outlined" className={classes.containerCard}>
      {!property ? (
        <>
          <Separator size="8px" />
          <LoaderSmall />
        </>
      ) : (
        <div className={classes.sidebar}>
          <div>
            {renderLogo()}
            {renderCarousel()}
            <Separator size="24px" />
            <div className={classes.divider}>
              <Divider style={{ width: '70%' }} />
            </div>
            <Separator size="16px" />
            {property.publication?.showAddress && (
              <div className={classes.flex}>
                <PlaceOutlinedIcon className={classes.icon} />
                <Body1
                  className={classes.description}
                >{`${property.location?.streetAddress}, ${property.location?.locality}`}</Body1>
              </div>
            )}
          </div>
          <div className={classes.containerButton}>
            {selected && (
              <>
                <div className={classes.dateDuration}>
                  <div className={classes.flex}>
                    <EventIcon className={classes.greyColor} />
                    <Body1 className={classes.greyColor}>{i18n.t('date_and_time')}</Body1>
                  </div>
                  <Body1 className={classes.blueDarkColor}>
                    {i18n.t('@date_abbreviated', { value: DateTime.fromISO(selected) })}
                  </Body1>
                </div>
                <Separator size="8px" />
                <div className={classes.dateDuration}>
                  <div className={classes.flex}>
                    <TimerOutlinedIcon className={classes.greyColor} />
                    <Body1 className={classes.greyColor}>{i18n.t('duration')}</Body1>
                  </div>
                  <Body1 className={classes.blueDarkColor}>{renderDuration(mission)}</Body1>
                </div>
              </>
            )}
            <Separator size="24px" />
            {isBooking ? (
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={!selected}
                type="submit"
                className={classes.loadingButton}
                loading={isBooking}
                onClick={handleSubmitBooking}
              >
                {toNameCase(i18n.t('book_my_appointment'))}
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={!selected}
                type="submit"
                onClick={handleSubmitBooking}
              >
                {i18n.t('book_my_appointment')}
              </Button>
            )}
          </div>
        </div>
      )}
    </Card>
  )
}

export default Sidebar
