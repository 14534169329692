import { makeStyles } from '@material-ui/core'
import { SeparatorProps } from './Type'

export const useStyle = makeStyles(() => {
  return {
    separator: (props: SeparatorProps) => ({
      width: '100%',
      height: props.size,
      minHeight: props.size,
    }),
  }
})
