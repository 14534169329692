import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useStyle } from './Style'
import { SmallProps } from './Type'

const Small: React.FC<SmallProps> = ({ primary }): React.ReactElement => {
  const classes = useStyle()

  return (
    <div className={classes.container} data-testid="loaderSmall">
      <div className={classes.inner}>
        <CircularProgress color={primary ? 'primary' : 'primary'} />
      </div>
    </div>
  )
}

export default Small
