/* eslint-disable jsx-a11y/anchor-is-valid */
import Alert from '@flatsy/core/Alert'
import { AlertRef } from '@flatsy/core/Alert/Type'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux'

const AppAlert: React.FC = () => {
  const alertRef = useRef<AlertRef>(null)
  const alertState = useSelector((state: RootState) => state.alert.alertProps)
  useEffect(() => {
    if (alertRef && alertRef.current && alertState) {
      alertRef.current?.setAlert(alertState)
    }
  }, [alertState, alertRef])
  return <Alert ref={alertRef} />
}

export default AppAlert
