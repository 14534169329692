/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { FlatsySystemProperty, FlatsyZone } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Admin<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description (Admin) Retrieve a <<glossary:Property>> by its id
   *
   * @tags Property
   * @name PropertyDetail
   * @summary (Admin) Retrieve a Property by its id
   * @request GET:/admin/property/{id}
   * @secure
   */
  propertyDetail = (id: string, params: RequestParams = {}) =>
    this.request<FlatsySystemProperty, void>({
      path: `/admin/property/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Zone
   * @name ZoneList
   * @summary (Admin) Retrieve Zones
   * @request GET:/admin/zone/
   * @secure
   */
  zoneList = (
    query?: {
      /**
       * Country code
       * @example "FR"
       */
      country_code?: string
      /**
       * Administrative code
       * @example "75"
       */
      administrative_code?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      {
        /** Collection of Zones. */
        items?: FlatsyZone[]
      },
      any
    >({
      path: `/admin/zone/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    })
}
