import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  topBar: {
    width: '100%',
    height: 'auto',
    background: `${theme.palette.common.white}!important`,
    backgroundColor: `${theme.palette.common.white}!important`,
    padding: '16px',
    border: 'none',
    display: 'flex',
    justify: 'space-between',
    alignItems: 'center',
    textTransform: 'none',
    boxShadow: '0px 1px 2px 0px rgba(74, 75, 83, 0.04)',
  },
  topBarContent: {
    display: 'flex',
    justify: 'flex-end',
    alignItems: 'center',
    gap: 4,
    textTransform: 'none',
  },
  logoWrapper: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    paddingRight: 16,
  },
  logo: {
    width: '100%',
    maxHeight: 40,
    objectFit: 'contain',
    objectPosition: 'left center',
  },
  logoContainer: {
    maxWidth: 200,
    height: 'auto',
  },
  iconMore: {
    color: theme.palette.secondary.dark,
  },
}))

export default useStyles
