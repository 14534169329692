import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as ROUTES from 'src/constants/Routes'
import Booking from 'src/containers/Application/Booking'
import SuccessRoute from 'src/containers/Application/Booking/Overview/Success/Routes'
import { ApplicationProvider } from 'src/context/Application'

const ApplicationRouter: React.FC = (): React.ReactElement => {
  return (
    <ApplicationProvider>
      <Switch>
        <Route exact path={ROUTES.APPLICATION} component={Booking} />
        <Route exact path={ROUTES.APPLICATION_SUCCESS} component={SuccessRoute} />
        {/* <Route exact path={ROUTES.APPLICATION_FILES} component={Files} /> */}
        <Redirect to={{ pathname: ROUTES.NOT_FOUND }} />
      </Switch>
    </ApplicationProvider>
  )
}

export default ApplicationRouter
