/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IconButton } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import PhotoCameraIcon from '@material-ui/icons/PhotoCameraOutlined'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { Body1 } from '../MaterialUi/Typo'
import { useCarousel } from './Hooks'
import ModalImage from './Modal'
import { useStyle } from './Style'
import { ICarousel, MoveFn } from './Type'

const Carousel = ({ id, slides, noBorderRadius }: ICarousel): React.ReactElement => {
  const classes = useStyle()

  const [open, setOpen] = useState<{ open: boolean; position: number }>({
    open: false,
    position: 0,
  })

  const moveSlider = (values: MoveFn) => {
    if (values.moverElement) {
      values.moverElement.style.transform = `translateX(calc(-${values.index} * (${values.imageSize}px))`
    }
  }

  const handleClickModal = (event: React.MouseEvent<HTMLDivElement>, key: number) => {
    event?.preventDefault()
    setOpen({
      open: true,
      position: key,
    })
  }

  const carousel = useCarousel({
    slides,
    move: moveSlider,
    id: `${id}-preview`,
    numberOfImageInScreen: 1,
  })

  const handleClickPrev = (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
    event?.preventDefault()
    carousel.event.previous()
  }

  const handleClickNext = (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
    event?.preventDefault()
    carousel.event.next()
  }

  const handleSwipe = (direction: 'Left' | 'Right' | 'Up' | 'Down') => {
    if (direction === 'Left') {
      carousel.event.next()
    } else if (direction === 'Right') {
      carousel.event.previous()
    }
  }

  const swipeHandler = useSwipeable({
    onSwiped: (eventData) => handleSwipe(eventData.dir),
  })

  return (
    <div
      className={noBorderRadius ? classes.containerNoRadius : classes.container}
      {...swipeHandler}
    >
      <div className={classes.mover} id={carousel.ids.mover}>
        {slides.map((slide, key) => (
          <div
            className={clsx(classes.slideModal, carousel.ids.item)}
            onClick={(event) => handleClickModal(event, key)}
            key={key}
          >
            <img src={slide} alt="asset" className={classes.img} />
          </div>
        ))}
      </div>
      <IconButton
        onClick={handleClickPrev}
        className={clsx(
          classes.arrowButton,
          carousel.showArrow.prev ? classes.arrowButtonLeft : classes.arrowHidden
        )}
      >
        <ChevronLeft className={classes.arrow} />
      </IconButton>
      <IconButton
        onClick={handleClickNext}
        className={clsx(
          classes.arrowButton,
          carousel.showArrow.next ? classes.arrowButtonRight : classes.arrowHidden
        )}
      >
        <ChevronRight className={classes.arrow} />
      </IconButton>
      <div className={classes.nbPicture}>
        <PhotoCameraIcon />
        <Body1 className={classes.body1}>{carousel.totalOfSlide}</Body1>
      </div>
      <ModalImage
        id={`${id}-full`}
        open={open.open}
        positions={open.position}
        setOpen={setOpen}
        slides={slides}
      />
    </div>
  )
}

export default Carousel
