import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => {
  const spacing = theme.spacing(2)
  return {
    wrapper: {
      backgroundColor: '#FFF',
      width: '100%',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      [theme.breakpoints.down('xs')]: {
        border: 'none',
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 10px 1px rgb(149, 150, 154,0.15)',
      },
    },
    header: {
      backgroundColor: '#FFF',
      margin: 'auto',
      width: '100%',
      maxWidth: 1440,
      paddingLeft: 32,
      paddingRight: 32,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: spacing,
        paddingRight: spacing,
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        paddingTop: 16,
        backgroundColor: 'transparent',
      },
    },
    headerFullWidth: {
      width: '100%',
      maxWidth: '100%',
    },
    content: {
      margin: 'auto',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1.5),
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        paddingBottom: 16,
      },
    },
    wrap: {
      flexWrap: 'wrap',
    },
    titleStart: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
      },
    },
    titleMobile: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    mainTitleMobile: {
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.primary.main,
      marginBottom: 20,
    },
    mainTitle: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        whiteSpace: 'initial',
      },
    },
  }
})
