import { MISSION_BASE_URL } from '../../constants'
import { Admin } from './Admin'
import { Pro } from './Pro'
import { Public } from './Public'

const configuration = { baseURL: MISSION_BASE_URL }

export const FlatsyPublicMissionClient = new Public(configuration)
export const FlatsyAdminMissionClient = new Admin(configuration)
export const FlatsyProMissionClient = (hybridToken: string): Pro =>
  new Pro({ ...configuration, hybridToken })
