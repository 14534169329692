import useStyles from './Style'

export const Line = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.containerLine}>
      <div className={classes.lineGrey} />
    </div>
  )
}
