import DateFnsUtils from '@date-io/date-fns'
import LoaderWrapper from '@flatsy/core/Loader/Wrapper'
import i18n from '@flatsy/utils/translations'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import frLocale from 'date-fns/locale/fr'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import store from 'src/redux'
import App from './containers/App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import './static/assets/fonts/sfpro/font.css'

// if (process.env.REACT_APP_SENTRY_DNS) {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DNS,
//     beforeSend(event, _) {
//       if (event.exception && process.env.REACT_APP_ENVIRONEMENT !== 'production') {
//         Sentry.showReportDialog({ eventId: event.event_id })
//       }
//       return event
//     },
//   })

//   if (process.env.REACT_APP_ENVIRONEMENT === 'production') {
//     LogRocket.init('flatsy/next', {
//       dom: {
//         textSanitizer: true,
//         inputSanitizer: true,
//       },
//     })
//     LogRocket.getSessionURL((sessionURL) => {
//       Sentry.configureScope((scope) => {
//         scope.setExtra('sessionURL', sessionURL)
//       })
//     })
//   }
// }

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<LoaderWrapper absolute />}>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
            {/* {process.env.REACT_APP_SENTRY_DNS ? (
              <Sentry.ErrorBoundary fallback="An error has occurred">
                <App />
              </Sentry.ErrorBoundary>
            ) : ( */}
            <App />
            {/* )} */}
          </MuiPickersUtilsProvider>
        </Provider>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
