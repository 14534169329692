import theme from '@flatsy/core/MuiTheme/theme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { HookReturn } from './Type'

const useUtils = (): HookReturn => {
  const screenMedium = useMediaQuery(theme.breakpoints.up('md'))
  const screenSmall = useMediaQuery(theme.breakpoints.up('sm'))
  const screenXs = useMediaQuery(theme.breakpoints.down('sm'))

  const getNumberDays = () => {
    if (screenXs) {
      return 5
    }

    if (screenMedium) {
      return 7
    }

    if (screenSmall) {
      return 5
    }
    return 2
  }

  const numberDays: number = getNumberDays()

  return {
    numberDays,
    screenMedium,
    screenSmall,
    screenXs,
  }
}

export default useUtils
