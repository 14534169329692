import { FlatsyPublicMissionClient } from '@flatsy/api/client/3.0.0/services/Mission'
import {
  FlatsyPublicBookable,
  FlatsyPublicBooking,
} from '@flatsy/api/client/3.0.0/services/Mission/data-contracts'
import { AxiosResponse } from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

export const useBookableByMissionId = (
  publicToken: string,
  missionId: string,
  startsAt: string,
  endsAt: string
): {
  bookable: UseQueryResult<FlatsyPublicBookable[]>
  createBooking: (startsAt: string) => Promise<AxiosResponse<FlatsyPublicBooking>>
} => {
  const bookable = useQuery({
    queryKey: ['bookable_mission', publicToken, missionId, startsAt, endsAt],
    queryFn: () => {
      return FlatsyPublicMissionClient.bookableList(
        {
          mission_id: missionId,
          starts_at: startsAt,
          ends_at: endsAt,
        },
        {
          headers: {
            Authorization: `Bearer ${publicToken}`,
            Accept: '*/*',
          },
        }
      ).then((response) => response.data.items || [])
    },
    keepPreviousData: true,
    cacheTime: 0,
    retry: 0,
    enabled: endsAt !== undefined && startsAt !== undefined,
  })

  const createBooking = (startsAt: string) => {
    return FlatsyPublicMissionClient.bookingCreate(
      { missionId, startsAt },
      {
        headers: {
          Authorization: `Bearer ${publicToken}`,
          Accept: '*/*',
        },
      }
    )
  }

  return { bookable, createBooking }
}
