import { useResizeWindow } from '@flatsy/utils/Hooks/ResizeWindow'
import { IconButton } from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { Grid } from '@mui/material'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useSlots, useUtils } from '../Hooks'
import Day from './Day'
import DaysEmpty from './DaysEmpty'
import LoadingsSlot from './Loading'
import useStyle from './Styles'
import { WeeksProps } from './Type'

const Weeks: React.FC<WeeksProps> = ({
  handleClickOnSlot,
  handleWeekChange,
  slots,
  isLoading,
  selectedDay,
  startDate,
  dataBooking,
  isInsideDate,
}): React.ReactElement => {
  const indexPage = 0
  const [transitionLoading, setTransitionLoading] = useState(true)
  const { slotsByDay } = useSlots(slots)
  const { numberDays } = useUtils()
  const classes = useStyle({ numberDays })
  const { screenHeight } = useResizeWindow()

  const onIncrementPage = () => {
    const next = DateTime.fromJSDate(startDate || new Date()).plus({ week: 1 })
    handleWeekChange && handleWeekChange(next.toISODate())
  }

  const onDecrementPage = () => {
    const previous = DateTime.fromJSDate(startDate || new Date()).minus({ week: 1 })
    handleWeekChange && handleWeekChange(previous.toISODate())
  }

  const renderDays = () => {
    const days = []
    if ((dataBooking && dataBooking === 0) || isInsideDate === false) {
      for (let i = indexPage * numberDays; i < (indexPage + 1) * numberDays; i++) {
        days.push(
          <Grid
            item
            style={{
              flex: 1,
            }}
          >
            <DaysEmpty index={i} slotsByDay={slotsByDay} startDate={startDate} />
          </Grid>
        )
      }
      return days.filter((el) => el !== null)
    }
    for (let i = indexPage * numberDays; i < (indexPage + 1) * numberDays; i++) {
      days.push(
        <Grid
          item
          style={{
            flex: 1,
          }}
        >
          <Day
            index={i}
            handleClickOnSlot={handleClickOnSlot}
            slotsByDay={slotsByDay}
            selectedDay={selectedDay}
            startDate={startDate}
            screenHeight={screenHeight - 270}
          />
        </Grid>
      )
    }
    return days.filter((el) => el !== null)
  }

  useEffect(() => {
    setTimeout(() => {
      setTransitionLoading(false)
    }, 300)
  }, [])

  if (isLoading || transitionLoading) {
    return <LoadingsSlot />
  }

  const previousDisabled = () => {
    if (startDate && DateTime.fromJSDate(startDate) < DateTime.now()) {
      return true
    }
    return false
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs="auto">
        <IconButton
          onClick={onDecrementPage}
          className={classes.decrementButton}
          color="primary"
          disabled={previousDisabled()}
        >
          <ChevronLeft fontSize="large" />
        </IconButton>
      </Grid>
      <Grid
        container
        spacing={0}
        xs="auto"
        columns={16}
        justifyContent="space-between"
        style={{
          flex: 1,
        }}
      >
        {renderDays()}
      </Grid>
      <Grid item xs="auto">
        <IconButton
          onClick={onIncrementPage}
          color="primary"
          data-cy="visitorSlot__rightArrow"
          className={classes.incrementButton}
        >
          <ChevronRight fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default Weeks
