import { FlatsyPublicFlatguideClient } from '@flatsy/api/client/2.1.0/services/Flatguide'
import { FlatsyFlatguide } from '@flatsy/api/client/2.1.0/services/Flatguide/data-contracts'
import { UseQueryResult, useQuery } from 'react-query'

export const useFlatguideById = (flatguideId: string): UseQueryResult<FlatsyFlatguide> => {
  const flatguide = useQuery({
    queryKey: ['flatguide', flatguideId],
    queryFn: () => {
      return FlatsyPublicFlatguideClient.flatguideDetail(flatguideId).then((response) => {
        return response.data
      })
    },
    keepPreviousData: true,
    structuralSharing: true,
    cacheTime: 10 * 60,
    enabled: flatguideId !== undefined && flatguideId !== '',
  })
  return flatguide
}
