import { FlatsyPublicMissionClient } from '@flatsy/api/client/3.0.0/services/Mission'
import {
  FlatsyPublicMissionCommercialViewing,
  FlatsyPublicMissionInspection,
} from '@flatsy/api/client/3.0.0/services/Mission/data-contracts'
import { UseQueryResult, useQuery } from 'react-query'

export const useMissionForToken = (
  publicToken: string
): UseQueryResult<FlatsyPublicMissionInspection | FlatsyPublicMissionCommercialViewing> => {
  const mission = useQuery({
    queryKey: ['missionPublicToken'],
    queryFn: () => {
      return FlatsyPublicMissionClient.missionForTokenList({
        headers: {
          Authorization: `Bearer ${publicToken}`,
          Accept: '*/*',
        },
      }).then((response) => {
        return response.data
      })
    },
    keepPreviousData: true,
    structuralSharing: true,
    cacheTime: 10 * 60,
  })
  return mission
}
