import { FLATGUIDE_BASE_URL } from '../../constants'
import { Admin } from './Admin'
import { Pro } from './Pro'
import { Public } from './Public'

const configuration = { baseURL: FLATGUIDE_BASE_URL }

export const FlatsyPublicFlatguideClient = new Public(configuration)
export const FlatsyProFlatguideClient = (hybridToken: string): Pro =>
  new Pro({ ...configuration, hybridToken })
export const FlatsyAdminFlatguideClient = new Admin(configuration)
