import { LayoutMain } from '@flatsy/core'
import clsx from 'clsx'
import React from 'react'
import Navigation from 'src/containers/Files/Navigation'
import { useMobile } from 'src/hooks/Mobile'
import { BookingMobile } from './Mobile'
import BookingOverview from './Overview'
import { useStyles } from './Style'

const Booking: React.FC = (): React.ReactElement => {
  const isMobile = useMobile()
  const classes = useStyles()

  if (isMobile) {
    return (
      <>
        <Navigation />
        <BookingMobile />
      </>
    )
  }

  return (
    <LayoutMain className={clsx(classes.main)}>
      <BookingOverview />
    </LayoutMain>
  )
}

export default Booking
