import { makeStyles } from '@material-ui/core'

export const useStyleConfirm = makeStyles((theme) => {
  const borderColor = '#DFE6ED'
  return {
    confirmContent: {
      backgroundColor: '#F7F9FA',
      borderTop: `solid 1px ${borderColor}`,
      borderBottom: `solid 1px ${borderColor}`,
      paddingTop: 24,
      paddingRight: 40,
      paddingLeft: 40,
      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },
    },
    confirmContentWhite: {
      backgroundColor: theme.palette.common.white,
      borderBottom: `solid 1px ${borderColor}`,
      padding: '0px!important',
      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },
      '& .MuiTypography-root.MuiDialogContentText-root.MuiTypography-body1': {
        marginBottom: '0px!important',
      },
    },
  }
})
