import { makeStyles, Theme } from '@material-ui/core'
import theme from '@flatsy/core/MuiTheme/theme'

const useStyle = makeStyles<Theme, { numberDays: number }>({
  picker: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'start',
    position: 'relative',
    overflowY: 'hidden',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  divider: {
    position: 'absolute',
    top: 60,
    left: 60,
    right: 60,
    height: 1,
    backgroundColor: theme.palette.grey[400],
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  days: {
    width: 'calc(100% - 2*60px)',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      order: 3,
      transform: 'translateY(-59px)',
    },
  },
  decrementButton: {
    [theme.breakpoints.down('sm')]: {
      order: 0,
      zIndex: 2,
      position: 'relative',
      paddingLeft: 0,
    },
  },
  incrementButton: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
      zIndex: 2,
      position: 'relative',
      paddingRight: 0,
    },
  },
})

export default useStyle
