import React from 'react'
import clsx from 'clsx'
import LoaderSmall from '../Small'
import { useStyle } from './Style'
import { WrapperProps } from './Type'

const Wrapper: React.FC<WrapperProps> = ({
  absolute,
  fullWidth,
  fixedContainer,
  primary,
}): React.ReactElement => {
  const classes = useStyle()

  return (
    <div
      data-testid="loaderWrapper"
      className={clsx(
        classes.container,
        fixedContainer && classes.fixed,
        fullWidth && classes.fullWidth
      )}
    >
      <div className={clsx(classes.inner, absolute && classes.absolute)}>
        <LoaderSmall primary={primary} />
      </div>
    </div>
  )
}

export default Wrapper
