/* eslint-disable i18next/no-literal-string */
import FlatsyTheme from '@flatsy/core/MuiTheme/flatsyTheme'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import React from 'react'
import { useStyle } from './Style'

const NotFound: React.FC = (): React.ReactElement => {
  const classes = useStyle()
  const { i18n } = useTranslation()

  return (
    <div className={classes.container}>
      <img src={FlatsyTheme.image.notFound_color} alt="Not found" className={classes.image} />
      <Typography variant="body1" className={classes.title}>
        404
      </Typography>
      <Typography>{i18n.t('page_not_found')}</Typography>
    </div>
  )
}

export default NotFound
