/* eslint-disable import/no-cycle */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlertProps } from '@flatsy/core/Alert/Type'
import { AlertState } from './Type'

export const initialState: AlertState = {
  alertProps: {
    open: false,
    content: '',
    severity: 'info',
    icon: null,
  },
}

export const AlertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<AlertProps>) => {
      state.alertProps = action.payload
    },
  },
  extraReducers: () => {},
})
