import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => {
  return {
    h3: {
      marginBottom: 32,
      color: theme.palette.common.black,
      fontWeight: 300,
      fontSize: 18,
      lineHeight: '21px',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: '21px',
      },
    },
    h3WithLine: {
      marginBottom: 16,
    },
    h3Secondary: {
      marginTop: 32,
    },
    line: {
      width: '100%',
      height: 1,
      background: theme.palette.secondary.light,
      marginBottom: 16,
    },
  }
})
