/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FlatsyBaseCustomer,
  FlatsyCustomerBilling,
  FlatsyCustomerContacts,
  FlatsyCustomerFeatures,
  FlatsyCustomerIntegrations,
  FlatsyCustomerInvoicing,
  FlatsyCustomerNotifications,
  FlatsyCustomerOperations,
  FlatsyCustomerPayment,
  FlatsyCustomerRelation,
  FlatsyCustomerSegment,
  FlatsyPatchedCustomerRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Admin<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Customer
   * @name CustomerDetail
   * @summary (Admin) Retrieve a Customer by its id
   * @request GET:/admin/customer/{id}
   * @secure
   */
  customerDetail = (id: string, params: RequestParams = {}) =>
    this.request<
      FlatsyBaseCustomer & {
        contacts?: FlatsyCustomerContacts
        enabledFeatures?: FlatsyCustomerFeatures
        /** List of third party systems' information where the Property may be used */
        integrations?: FlatsyCustomerIntegrations
        billing?: FlatsyCustomerBilling
        invoicing?: FlatsyCustomerInvoicing
        payment?: FlatsyCustomerPayment
        notifications?: FlatsyCustomerNotifications
        operations?: FlatsyCustomerOperations
        relations?: FlatsyCustomerRelation
      },
      void
    >({
      path: `/admin/customer/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Customer
   * @name CustomerPartialUpdate
   * @summary (Admin) Update the Billing Information for an existing Customer
   * @request PATCH:/admin/customer/{id}
   * @secure
   */
  customerPartialUpdate = (
    id: string,
    data: FlatsyPatchedCustomerRequest,
    params: RequestParams = {}
  ) =>
    this.request<FlatsyPatchedCustomerRequest, any>({
      path: `/admin/customer/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags Segment
   * @name SegmentList
   * @summary (Admin) Retrieve all Customer Segments
   * @request GET:/admin/segment/
   * @secure
   */
  segmentList = (params: RequestParams = {}) =>
    this.request<
      {
        /**
         * The total of items found
         * @example 123
         */
        count?: number
      } & {
        /** Collection of Customer Segments. */
        items?: FlatsyCustomerSegment[]
      },
      any
    >({
      path: `/admin/segment/`,
      method: 'GET',
      secure: true,
      ...params,
    })
}
