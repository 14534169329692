import React from 'react'
import Navigation from 'src/containers/Files/Navigation'
import { useMobile } from 'src/hooks/Mobile'
import Success from '.'
import { SuccessMobile } from '../../Mobile/Success'

const SuccessRoute: React.FC = (): React.ReactElement => {
  const isMobile = useMobile()

  if (isMobile) {
    return (
      <>
        <Navigation />
        <SuccessMobile />
      </>
    )
  }

  return <Success />
}

export default SuccessRoute
