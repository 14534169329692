/* eslint-disable @typescript-eslint/ban-ts-comment */
import clsx from 'clsx'
import theme from '../../../MuiTheme/theme'
import { ExtenedTypographyProps } from '../Type'
import { useTypoStyles } from '../Utile'
import {
  Body1Style,
  Body2Style,
  Body3Style,
  SubBody1Style,
  SubBody2Style,
  SubBody3Style,
} from './Styles'

/* --------------------------------- BODY 1 --------------------------------- */

export const Body1: React.FC<ExtenedTypographyProps> = ({
  className,
  children,
  fontColor,
  component,
  align,
  ...props
}) => {
  const classes = useTypoStyles({
    fontColor,
    ...props,
  })
  return (
    <Body1Style
      // @ts-ignore
      component={component}
      align={align}
      className={clsx(className, classes.color)}
      {...props}
    >
      {children}
    </Body1Style>
  )
}

/* --------------------------------- BODY 2 --------------------------------- */

export const Body2: React.FC<ExtenedTypographyProps> = ({
  className,
  children,
  fontColor,
  component,
  align,
  ...props
}): React.ReactElement => {
  const classes = useTypoStyles({
    fontColor,
    ...props,
  })
  return (
    <Body2Style
      // @ts-ignore
      component={component}
      align={align}
      className={clsx(className, classes.color)}
      {...props}
    >
      {children}
    </Body2Style>
  )
}

/* --------------------------------- BODY 3 --------------------------------- */

export const Body3: React.FC<ExtenedTypographyProps> = ({
  className,
  children,
  fontColor,
  component,
  align,
  lowerCase,
  ...props
}) => {
  const classes = useTypoStyles({
    fontColor,
    ...props,
  })
  return (
    <Body3Style
      // @ts-ignore
      component={component}
      align={align}
      className={clsx(lowerCase ? classes.Lower : undefined, className, classes.color)}
      {...props}
    >
      {children}
    </Body3Style>
  )
}

/* --------------------------------- SUB BODY 1 --------------------------------- */

export const SubBody1: React.FC<ExtenedTypographyProps> = ({
  className,
  children,
  fontColor,
  component,
  align,
  ...props
}) => {
  const classes = useTypoStyles({
    fontColor: fontColor || theme.palette.secondary.dark,
    ...props,
  })
  return (
    <SubBody1Style
      // @ts-ignore
      component={component}
      align={align}
      className={clsx(className, classes.color)}
      {...props}
    >
      {children}
    </SubBody1Style>
  )
}

/* --------------------------------- SUB BODY 2 --------------------------------- */

export const SubBody2: React.FC<ExtenedTypographyProps> = ({
  className,
  children,
  fontColor,
  component,
  align,
  ...props
}) => {
  const classes = useTypoStyles({
    fontColor: fontColor || theme.palette.secondary.dark,
    ...props,
  })
  return (
    <SubBody2Style
      // @ts-ignore
      component={component}
      align={align}
      className={clsx(className, classes.color)}
      {...props}
    >
      {children}
    </SubBody2Style>
  )
}

/* --------------------------------- SUB BODY 3 --------------------------------- */

export const SubBody3: React.FC<ExtenedTypographyProps> = ({
  className,
  children,
  fontColor,
  component,
  align,
  ...props
}) => {
  const classes = useTypoStyles({
    fontColor: fontColor || theme.palette.secondary.dark,
    ...props,
  })
  return (
    <SubBody3Style
      // @ts-ignore
      component={component}
      align={align}
      className={clsx(className, classes.color)}
      {...props}
    >
      {children}
    </SubBody3Style>
  )
}
