import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  main: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiDialog-container': {
        alignItems: 'flex-end',
      },
      '& .MuiPaper-root.MuiDialog-paper': {
        width: '100vw',
        minWidth: '100vw',
        maxWidth: '100vw',
        margin: 0,
        maxHeight: 'calc(100% - 32px)',
      },
    },
  },
  dialogTitle: {
    padding: '0!important',
    '& .MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 18,
      lineHeight: '24px',
      textTransform: 'initial',
    },
  },
  dialogTitleWhite: {
    padding: '0!important',
    '& .MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 18,
      lineHeight: '24px',
      textTransform: 'initial',
    },
  },
  title: {
    fontSize: '1.375rem!important',
    lineHeight: '2rem!important',
    paddingRight: '1.5rem!important',
  },
  btnConfirm: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  dialogAction: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 24,
  },
  dialogActionCustom: {
    display: 'flex',
    justifyContent: 'space-between !important',
    padding: '16px 32px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1.5rem 1rem 1.5rem',
  },
  bottomCustom: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnClose: {
    marginRight: 16,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
  },
}))
