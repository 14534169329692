import { useEffect, useState } from 'react'
import { HookReturn } from './Type'

export const useResizeWindow = (): HookReturn => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)

  const handleResize = () => {
    setScreenHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { screenHeight }
}
